import { Box, Stack, Typography, Chip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AssetRisk } from "containers/StrategyPage/strategyPage.types";
import { Drawer } from "glue";

import { StrategyTable } from "./components/StrategyTable";

type Props = {
    asset?: AssetRisk;
    isOpen: boolean;
    onClose: () => void;
};

export const StrategyDrawer = (props: Props) => {
    const { asset, ...rest } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    const info = [
        {
            label: t("assetDetailsComponent.propertyType"),
            value: asset?.crremPropertyType,
        },
        {
            label: t("assetDetailsComponent.totalArea"),
            value: `${asset?.buildingArea} m³`,
        },
        {
            label: t("assetDetailsComponent.constructionYear"),
            value: new Date(asset?.constructionDate).getFullYear(),
        },
        {
            label: "Completion of green asset indicator",
            value: "1/6",
        },
    ];

    // Dummy data for visualization
    const epcs = [
        {
            kpi: "EPC",
            current: (
                <Chip
                    sx={{
                        color: theme.palette.epc.d.main,
                        backgroundColor: theme.palette.epc.d.light,
                        height: 20,
                    }}
                    label="D"
                />
            ),
            target: (
                <Chip
                    sx={{
                        color: theme.palette.epc.c.main,
                        backgroundColor: theme.palette.epc.c.light,
                        height: 20,
                    }}
                    label="C"
                />
            ),

            unit: "Rating",
            completed: false,
        },
        {
            kpi: "EPC",
            current: (
                <Chip
                    sx={{
                        color: theme.palette.epc.d.main,
                        backgroundColor: theme.palette.epc.d.light,
                        height: 20,
                    }}
                    label="D"
                />
            ),
            target: (
                <Chip
                    sx={{
                        color: theme.palette.epc.c.main,
                        backgroundColor: theme.palette.epc.c.light,
                        height: 20,
                    }}
                    label="C"
                />
            ),

            unit: "Rating",
            completed: true,
        },
        {
            kpi: "EPC",
            current: (
                <Chip
                    sx={{
                        color: theme.palette.epc.d.main,
                        backgroundColor: theme.palette.epc.d.light,
                        height: 20,
                    }}
                    label="D"
                />
            ),
            target: (
                <Chip
                    sx={{
                        color: theme.palette.epc.c.main,
                        backgroundColor: theme.palette.epc.c.light,
                        height: 20,
                    }}
                    label="C"
                />
            ),

            unit: "Rating",
            completed: false,
        },
    ];

    const tableData = [
        {
            kpi: "Certification",
            current: "DGNB",
            target: "DGNB",
            unit: "Rating",
            completed: true,
        },
        {
            kpi: "Energy intensity",
            current: 28,
            target: 28,
            unit: "kWh/m2",
            completed: true,
        },
        {
            kpi: "Compliant until",
            current: "Not compliance",
            target: 2050,
            unit: "CRREM 1.5C",
            completed: false,
        },
        {
            kpi: "Carbon intensity",
            current: 35.5,
            target: 1.7,
            unit: "Kg CO2e/m2",
            completed: false,
        },
        {
            kpi: "Compliant until",
            current: 2021,
            target: 2040,
            unit: "CRREM 1.5C",
            completed: false,
        },
    ];

    const address = `${asset?.zipCode} ${asset?.city}`;

    return (
        <Drawer anchor="right" width="600px" {...rest}>
            <Drawer.Header>
                <Drawer.Title>{asset?.displayName}</Drawer.Title>
                <Drawer.Panel>
                    <Typography variant="faded">{address}</Typography>
                </Drawer.Panel>
            </Drawer.Header>
            <Drawer.Content>
                <Box sx={{ padding: "2rem" }}>
                    {info.map(({ label, value }) => (
                        <Stack
                            key={label}
                            direction="row"
                            justifyContent="space-between"
                            marginBottom={4}
                        >
                            <Typography fontWeight="bold">{label}</Typography>
                            <Typography>{value}</Typography>
                        </Stack>
                    ))}
                    <Typography width="75%" display="block" variant="faded">
                        The green asset indicator is benchmarked against Nordic
                        Banks Green finance framework. <a href="/">Read more</a>
                    </Typography>
                </Box>
                <Box sx={{ padding: "2rem" }}>
                    <Typography variant="h4" sx={{ marginBottom: 5 }}>
                        Asset level ESG actionplan
                    </Typography>
                    <StrategyTable data={tableData} epcs={epcs} />
                    <Typography variant="faded" sx={{ marginTop: 6 }}>
                        The ESG Actionplan supported by a Capex plan with
                        de-risking initiatives like heatpumps, solar pv, new
                        windows etc. with budget on the implementation. *DGNB in
                        use (explain what this mean).
                    </Typography>
                </Box>
            </Drawer.Content>
        </Drawer>
    );
};
