import { useFlags } from "launchdarkly-react-client-sdk";

import AssetListData from "components/AssetList/AssetListData";
import { AssetList } from "components/AssetListV2";
import { ActiveBenchmarkProvider } from "components/BenchmarkSelect/BenchmarkSelect";
import { BenchmarksProvider } from "utils/benchmarksContext";

function AssetListPage() {
    const { usePaginatedAssetList } = useFlags();

    return (
        <BenchmarksProvider>
            <ActiveBenchmarkProvider>
                {usePaginatedAssetList ? <AssetList /> : <AssetListData />}
            </ActiveBenchmarkProvider>
        </BenchmarksProvider>
    );
}

export default AssetListPage;
