import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import {
    Box,
    FormControl,
    MenuItem,
    SelectChangeEvent,
    Radio,
    Select,
    InputLabel,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export enum DropDownPerformanceType {
    ALL = "All",
    BELOW_BENCHMARK = "Below Benchmark",
    ABOVE_BENCHMARK = "Above Benchmark",
}

interface RadioDropDownProps {
    onChange: (event: DropDownPerformanceType) => void;
    selectedValue: DropDownPerformanceType;
}

export default function RadioDropdown(props: RadioDropDownProps) {
    const { onChange, selectedValue } = props;
    const { t } = useTranslation();

    const data = useMemo(
        () => [
            {
                value: DropDownPerformanceType.ALL,
                label: t("assetlistFilter.all", "All"),
            },
            {
                value: DropDownPerformanceType.BELOW_BENCHMARK,
                label: (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        <ArrowDownward color="success" fontSize="inherit" />
                        <span>Benchmark</span>
                    </Box>
                ),
            },
            {
                value: DropDownPerformanceType.ABOVE_BENCHMARK,
                label: (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        <ArrowUpward color="error" fontSize="inherit" />
                        <span>Benchmark</span>
                    </Box>
                ),
            },
        ],
        [t]
    );

    const onChangeHandler = useCallback(
        (e: SelectChangeEvent<unknown>, _: React.ReactNode) => {
            let value = (e.target as HTMLInputElement).value;
            value =
                typeof value === "string" ? value : DropDownPerformanceType.ALL;

            if (
                value === DropDownPerformanceType.ALL ||
                value === DropDownPerformanceType.BELOW_BENCHMARK ||
                value === DropDownPerformanceType.ABOVE_BENCHMARK
            ) {
                onChange(value as DropDownPerformanceType);
            }
        },
        [onChange]
    );

    return (
        <FormControl
            sx={{ minWidth: "150px", maxWidth: "250px", width: "100%" }}
        >
            <InputLabel id="verticals-label" size="small">
                {t(
                    "assessmentOverview.assetList.filter.performance",
                    "Performance"
                )}
            </InputLabel>

            <Select
                label="performance"
                variant="outlined"
                size="small"
                value={selectedValue}
                onChange={onChangeHandler}
                renderValue={(selected: any) =>
                    data.find((d) => selected === d.value)?.label
                }
            >
                {data.map((d) => (
                    <MenuItem key={d.value} value={d.value}>
                        <Radio checked={selectedValue === d.value} />
                        {d.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
