import { Box } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid";
import { useContext } from "react";

import BenchmarkSelect, {
    ActiveBenchmarkContext,
} from "components/BenchmarkSelect/BenchmarkSelect";
import {
    MarketBenchmarkInfo,
    PortfolioBenchmarkInfo,
} from "components/BenchmarkSelect/BenchmarkText";
import { TableHeader } from "components/Table";
import { BenchmarkType } from "graphql-types/graphql";

export const BenchmarkPerformanceHeader = (params: GridColumnHeaderParams) => {
    const { activeBenchmark } = useContext(ActiveBenchmarkContext);

    const infoPopText =
        activeBenchmark?.type === BenchmarkType.BASELINE ? (
            <PortfolioBenchmarkInfo />
        ) : (
            <MarketBenchmarkInfo />
        );

    return (
        <Box display="flex" flexDirection="column">
            <TableHeader params={params} infoText={infoPopText} />
            <BenchmarkSelect />
        </Box>
    );
};
