import { useQuery } from "@apollo/client";
import { PropsWithChildren, createContext, useMemo } from "react";

import {
    Benchmark,
    GetOrganizationBenchmarksQuery,
} from "graphql-types/graphql";
import { BENCHMARKS_QUERY } from "queries/benchmarksQuery";

type BenchmarksContextType = {
    loading: boolean;
    benchmarks: Benchmark[];
};

export const BenchmarksContext = createContext<BenchmarksContextType>({
    loading: false,
    benchmarks: [],
});

export const BenchmarksProvider = (props: PropsWithChildren) => {
    const { data, loading: benchmarkLoading } =
        useQuery<GetOrganizationBenchmarksQuery>(BENCHMARKS_QUERY);

    const isLoading = benchmarkLoading;

    const benchmarks = useMemo(() => {
        if (!data) return [];

        return data.benchmarks.edges.map((edge) => edge.node);
    }, [data]);

    return (
        <BenchmarksContext.Provider
            value={{
                benchmarks,
                loading: isLoading,
            }}
        >
            {props.children}
        </BenchmarksContext.Provider>
    );
};
