import {
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    Checkbox,
    ListItemText,
    SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";

import { DataSourceStatusFilter } from "containers/AssetPage/AssetDataOverview/types/dataSourceTypes";
import {
    IntegrationType,
    ConsumptionType,
    AllocationObjectType,
} from "graphql-types/graphql";
import {
    getIntegrationTypeTranslation,
    getConsumptionTypeTranslation,
    getPropertyObjectTypeTranslation,
} from "utils/translations";

import { Filter } from "./automatic-source-filter.types";
import { S } from "./AutomaticSourceFilter.style";
import {
    getFilterRenderValues,
    getStatusTranslations,
} from "../automatic-source-toolbar.helpers";

type Props = {
    options: Filter;
    filters: Filter;
    onFilterChange: (key: keyof Filter, value: Filter[keyof Filter]) => void;
};

export const AutomaticSourceFilter = (props: Props) => {
    const { filters, options, onFilterChange } = props;

    const { useConsumptionAllocation } = useFlags();

    const handleFilterChange = <K extends keyof Filter>(
        key: K,
        e: SelectChangeEvent<string | Filter[K]>
    ) => {
        const value =
            // On autofill we get a stringified value.
            (
                typeof e.target.value === "string"
                    ? e.target.value.split(",")
                    : e.target.value
            ) as Filter[K];

        onFilterChange(key, value);
    };

    return (
        <Box sx={{ display: "flex", gap: 4, alignItems: "center" }}>
            <FormControl>
                <InputLabel id="status-select-label" size="small">
                    {t("common.labels.status", "Status", {
                        ns: "translation",
                    })}
                </InputLabel>
                <S.Select<DataSourceStatusFilter[]>
                    multiple
                    size="small"
                    labelId="status-select-label"
                    id="status-select"
                    label={t("common.labels.status", {
                        ns: "translation",
                    })}
                    input={<OutlinedInput label="Status" />}
                    value={filters.source}
                    renderValue={(selectedValues) =>
                        getFilterRenderValues(
                            selectedValues,
                            options.source.length,
                            getStatusTranslations
                        )
                    }
                    onChange={(e) => handleFilterChange("source", e)}
                >
                    {options.source.map((value) => (
                        <S.MenuItem key={value} value={value} status={value}>
                            <Checkbox
                                checked={filters.source.includes(value)}
                                size="small"
                            />
                            <ListItemText primary={capitalize(value)} />
                        </S.MenuItem>
                    ))}
                </S.Select>
            </FormControl>
            <FormControl>
                <InputLabel id="source-select-label" size="small">
                    {t("common.labels.source", { ns: "translation" })}
                </InputLabel>
                <S.Select<IntegrationType[]>
                    multiple
                    size="small"
                    labelId="source-select-label"
                    label={t("common.labels.source", { ns: "translation" })}
                    id="source-select"
                    value={filters.integrationTypes}
                    defaultValue={options.integrationTypes}
                    onChange={(e) => handleFilterChange("integrationTypes", e)}
                    renderValue={(selectedValues) =>
                        getFilterRenderValues(
                            selectedValues,
                            options.integrationTypes.length,
                            getIntegrationTypeTranslation
                        )
                    }
                >
                    {options.integrationTypes.map((value, i) => (
                        <S.MenuItem key={`${value}-${i}`} value={value}>
                            <Checkbox
                                checked={filters.integrationTypes.includes(
                                    value
                                )}
                                size="small"
                            />
                            <ListItemText
                                primary={getIntegrationTypeTranslation(value)}
                            />
                        </S.MenuItem>
                    ))}
                </S.Select>
            </FormControl>
            <FormControl>
                <InputLabel id="consumptionType-select-label" size="small">
                    {t("common.labels.consumptionType", "Consumption Type", {
                        ns: "translation",
                    })}
                </InputLabel>
                <S.Select<ConsumptionType[]>
                    multiple
                    size="small"
                    labelId="consumptionType-select-label"
                    id="consumptionType-select"
                    label={t("common.labels.consumptionType", {
                        ns: "translation",
                    })}
                    value={filters.consumptionTypes}
                    defaultValue={options.consumptionTypes}
                    onChange={(e) => handleFilterChange("consumptionTypes", e)}
                    renderValue={(selectedValues) =>
                        getFilterRenderValues(
                            selectedValues,
                            options.consumptionTypes.length,
                            getConsumptionTypeTranslation
                        )
                    }
                >
                    {options.consumptionTypes.map((value, i) => (
                        <S.MenuItem key={`${value}-${i}`} value={value}>
                            <Checkbox
                                size="small"
                                checked={filters.consumptionTypes.includes(
                                    value
                                )}
                            />
                            <ListItemText
                                primary={getConsumptionTypeTranslation(
                                    value as ConsumptionType
                                )}
                            />
                        </S.MenuItem>
                    ))}
                </S.Select>
            </FormControl>
            {useConsumptionAllocation && (
                <FormControl>
                    <InputLabel
                        id="status-property-allocation-label"
                        size="small"
                    >
                        {t(
                            "common.labels.propertyAllocation",
                            "Property Allocation",
                            { ns: "translation" }
                        )}
                    </InputLabel>
                    <S.Select<AllocationObjectType[]>
                        multiple
                        size="small"
                        labelId="status-property-allocation-label"
                        id="property-allocation-select"
                        label={t("common.labels.propertyAllocation", {
                            ns: "translation",
                        })}
                        input={
                            <OutlinedInput
                                label={t("common.labels.propertyAllocation", {
                                    ns: "translation",
                                })}
                            />
                        }
                        value={filters.allocationTypes}
                        defaultValue={filters.allocationTypes}
                        renderValue={(selectedValues) =>
                            getFilterRenderValues(
                                selectedValues,
                                Object.values(AllocationObjectType).length,
                                getPropertyObjectTypeTranslation
                            )
                        }
                        onChange={(e) =>
                            handleFilterChange("allocationTypes", e)
                        }
                    >
                        {options.allocationTypes.map((value) => (
                            <S.MenuItem key={value} value={value}>
                                <Checkbox
                                    checked={filters.allocationTypes.includes(
                                        value
                                    )}
                                    size="small"
                                />
                                <ListItemText
                                    primary={getPropertyObjectTypeTranslation(
                                        value
                                    )}
                                />
                            </S.MenuItem>
                        ))}
                    </S.Select>
                </FormControl>
            )}
        </Box>
    );
};
