import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";
import { OrgEmissionSummaryFragment } from "graphql-types/graphql";

import { IntensityEmissionByMonth } from "./monthlyIntensityChart.types";
import { AssetEmissionChartTooltip } from "../AssetEmissionChartTooltip";
import { getMonthlyChartData } from "../helpers/monthlyChart.helpers";
import { getIntensityEmissionByMonth } from "../utils/portfolioIntensityByMonthChart.utils";

type Props = {
    orgEmissionSummaries: OrgEmissionSummaryFragment[];
};

export const MonthlyIntensityChart = (props: Props) => {
    const { orgEmissionSummaries } = props;

    const { t } = useTranslation();
    const { activeYear } = useSelectedYearContext();

    const intensityEmissionsByMonth = useMemo(() => {
        return getIntensityEmissionByMonth(orgEmissionSummaries);
    }, [orgEmissionSummaries]);

    const { series, xAxis, yAxis } = useMemo(() => {
        return getMonthlyChartData<IntensityEmissionByMonth>(
            intensityEmissionsByMonth,
            "emissionIntensity",
            t("portfolioDashboard.boxes.intensity", "Intensity"),
            activeYear,
            t
        );
    }, [intensityEmissionsByMonth, activeYear, t]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            customTooltip={AssetEmissionChartTooltip}
        />
    );
};
