import _ from "lodash";

import { AssetRisk } from "containers/StrategyPage/strategyPage.types";

import { StrategySeriesType } from "./chart.types";

export const yearFormatter = (date: Date) => date.getFullYear().toString();

export const co2Formatter = (value: number | null): string =>
    value === null ? "" : `${Math.round(value)} kg CO2e`;

export const groupedAssessmentSummariesByDate = (
    assessmentSummaries: AssetRisk["assessmentSummaries"]
) => {
    return _.groupBy(assessmentSummaries, (item) =>
        new Date(item.from).getFullYear()
    );
};

export function getXAxisYears(assets: AssetRisk[]): Date[] {
    // Extract all "from" dates from the assessmentSummaries of each asset
    const allFromDates = assets.flatMap((asset) =>
        (asset.assessmentSummaries || []).map(
            (summary) => new Date(summary.from)
        )
    );

    // Get unique dates and sort them
    const uniqueAndOrderedDates = _.sortBy(
        _.uniqBy(allFromDates, (date) => date.getTime())
    );

    return uniqueAndOrderedDates;
}

export function assetRiskListToChartSeries(
    assets: AssetRisk[]
): StrategySeriesType[] {
    return assets.map((asset) => {
        const grouped = groupedAssessmentSummariesByDate(
            asset.assessmentSummaries
        );

        const totalEmissions = _(grouped)
            .map((items, year) => ({
                year: parseInt(year),
                totalEmission: _.sumBy(
                    items,
                    ({ emissionDataGrams: { emissionPerArea } }) =>
                        emissionPerArea.bestEffort.ownedEmission ?? 0
                ),
            }))
            .orderBy("year") // Order by year in ascending order
            .map("totalEmission") // Extract only the totalEmission values
            .value();

        const { id, constructionDate, externalId } = asset;
        const constructionYear = constructionDate
            ? new Date(constructionDate).getFullYear()
            : undefined;

        return {
            id,
            internalId: externalId ?? undefined,
            label: asset.crremPropertyType ?? undefined,
            constructionYear,
            data: totalEmissions,
            type: "line",
            curve: "linear",
            valueFormatter: co2Formatter,
        };
    });
}
