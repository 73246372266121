import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import { ActiveBenchmarkContext } from "components/BenchmarkSelect/BenchmarkSelect";
import { DateContextType } from "components/Report/reportDateContext";
import {
    AssessmentDataType,
    GetAssetListAssessmentsQuery,
    GetAssetListAssessmentsQueryVariables,
} from "graphql-types/graphql";
import { useBrowserStorage } from "hooks";

import AssetListFilter, {
    FilterState,
    emptyFilterState,
} from "./AssetListFilter";
import AssetListOverview from "./AssetListOverview";
import { ASSET_LIST_QUERY } from "./assetListQuery";
import { AssetType, PerformanceAgainstBenchmarkType } from "./AssetListtypes";
import { AssetsCheckboxValue } from "./AssetsCheckbox";
import { DropDownPerformanceType } from "./RadioDropdown";
import {
    filterLocationByString,
    filterLocationByVertical,
    formatPercentage,
    getFormattedSearchParams,
    isValidFilter,
    mapLocations,
} from "./utils";
import Loading, { LoadingOverlay } from "../Loading/Loading";
import { getFileToDownloadName } from "../Report/report.helpers";
import {
    ACTIVE_YEAR_SEARCH_PARAMS,
    useSelectedYearContext,
} from "../YearSelector/YearContext";

function AssetListData() {
    const { t } = useTranslation(["translation", "report"]);

    const [activeAssetGroup] = useActiveAssetGroup();

    const { activeBenchmark } = useContext(ActiveBenchmarkContext);

    const [sessionStorageFilter, setSessionStorageFilter] =
        useBrowserStorage<FilterState>(
            "assetListFilter",
            emptyFilterState,
            null,
            "session"
        );

    const [searchParams, setSearchParams] = useSearchParams();
    const { activeYear, setActiveYear } = useSelectedYearContext();
    const [filters, setFilters] = useState<FilterState>(() => {
        const filter = Object.keys(sessionStorageFilter).reduce((acc, key) => {
            const param = searchParams.get(key);
            const value =
                param && isValidFilter(param, key as keyof FilterState)
                    ? JSON.parse(param)
                    : null;

            return {
                ...acc,
                ...(value ? { [key]: value } : {}),
            };
        }, sessionStorageFilter);

        return filter;
    });

    useEffect(() => {
        if (sessionStorageFilter !== emptyFilterState) {
            const formattedSearchParams = getFormattedSearchParams(filters);

            setSearchParams(
                new URLSearchParams({
                    [ACTIVE_YEAR_SEARCH_PARAMS]: activeYear.toString(),
                    ...formattedSearchParams,
                })
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = useCallback(
        (key: keyof FilterState | FilterState | "year", value?: any) => {
            if (typeof key === "object") {
                setFilters(key);
                setActiveYear(new Date().getFullYear(), false);
                return;
            }
            if (key === "year") {
                setActiveYear(value);
                return;
            }
            setSearchParams((prevState) =>
                value === "" || value.length === 0
                    ? (prevState.delete(key), prevState)
                    : (prevState.set(key, JSON.stringify(value)), prevState)
            );

            setFilters((prev) => ({ ...prev, [key]: value }));
            setSessionStorageFilter((prev) => ({ ...prev, [key]: value }));
        },
        [setSessionStorageFilter, setSearchParams, setActiveYear]
    );

    const activeAssessmentDataType = useMemo(() => {
        const sortedAssets = filters.assets
            .sort((a, b) => a.localeCompare(b))
            .toString();

        switch (sortedAssets.replace(/,/g, "")) {
            case AssetsCheckboxValue.EPC:
                return AssessmentDataType.ESTIMATE;
            case AssetsCheckboxValue.METER:
                return AssessmentDataType.ACTUAL;
            default:
                return AssessmentDataType.COMBINED;
        }
    }, [filters.assets]);

    const { data, loading, previousData } = useQuery<
        GetAssetListAssessmentsQuery,
        GetAssetListAssessmentsQueryVariables
    >(ASSET_LIST_QUERY, {
        variables: {
            fromLower: activeYear.toString(),
            fromUpper: `${activeYear}-12-31:23:59:59`,
            assessmentDataType: activeAssessmentDataType,
            benchmarkId: activeBenchmark?.id,
        },
    });

    const dataLoader = useMemo(
        () => data ?? previousData,
        [data, previousData]
    );

    const locations = dataLoader?.getAssets;

    const filteredLocationsBySearchString = useMemo(() => {
        const { searchInput, verticals } = filters;
        if (searchInput === "" && verticals.length === 0) {
            return locations || [];
        }

        return _.chain(locations)
            .filter(
                (l) =>
                    (l.purchaseDate
                        ? DateTime.fromISO(l.purchaseDate).year <= activeYear
                        : true) &&
                    (l.saleDate
                        ? DateTime.fromISO(l.saleDate).year >= activeYear
                        : true)
            )
            .filter((l) =>
                Boolean(
                    filterLocationByString(l, searchInput) &&
                        filterLocationByVertical(l, verticals)
                )
            )
            .value();
    }, [locations, activeYear, filters]);

    const performanceAgainstBenchmark: PerformanceAgainstBenchmarkType =
        useCallback(
            (performance) => {
                const percentage = performance
                    ? formatPercentage(performance)
                    : 0;

                switch (filters.performance) {
                    case DropDownPerformanceType.ALL:
                        return true;
                    case DropDownPerformanceType.BELOW_BENCHMARK:
                        return percentage < 0;
                    case DropDownPerformanceType.ABOVE_BENCHMARK:
                        return percentage > 0;
                }
            },
            [filters.performance]
        );

    const locationGrouping = useMemo(() => {
        if (!filteredLocationsBySearchString) {
            return null;
        }

        const groupAssets = (
            assetType: AssetType | null,
            showMissingData = false
        ) => {
            const filteredLocations = _.cloneDeep(
                filteredLocationsBySearchString
            ).filter((l) => {
                let hasMissingData = false;
                let hasAssetTypeData = false;

                if (assetType) {
                    hasAssetTypeData =
                        l.assessmentSummaries.length > 0 &&
                        l.assessmentSummaries.some(
                            (a) =>
                                a.emissionDataGrams.emission[assetType]
                                    .ownedEmission !== null
                        );
                }

                if (showMissingData) {
                    hasMissingData =
                        l.assessmentSummaries.length === 0 ||
                        l.buildingArea === null;
                }

                return (
                    (assetType && hasAssetTypeData) ||
                    (showMissingData && hasMissingData)
                );
            });

            return mapLocations(
                filteredLocations,
                activeYear,
                t,
                performanceAgainstBenchmark,
                activeBenchmark,
                assetType || AssetType.bestEffort
            );
        };

        const epcAssets = groupAssets(AssetType.epc);
        const meterAssets = groupAssets(AssetType.meter);
        const epcAndMeter = groupAssets(AssetType.bestEffort);

        const epcAndMissingData = groupAssets(AssetType.epc, true);
        const meterAndMissingData = groupAssets(AssetType.meter, true);
        const missingDataAssets = groupAssets(null, true);

        const allAssets = mapLocations(
            filteredLocationsBySearchString,
            activeYear,
            t,
            performanceAgainstBenchmark,
            activeBenchmark,
            AssetType.bestEffort
        );

        return {
            allAssets,
            epcAssets,
            missingDataAssets,
            epcAndMeter,
            epcAndMissingData,
            meterAssets,
            meterAndMissingData,
        };
    }, [
        filteredLocationsBySearchString,
        activeBenchmark,
        performanceAgainstBenchmark,
        activeYear,
        t,
    ]);

    const activeLocations = useMemo(() => {
        const sortedAssets = filters.assets
            .sort((a, b) => a.localeCompare(b))
            .toString();

        if (!locationGrouping) {
            return [];
        }

        switch (sortedAssets.replace(/,/g, "")) {
            case AssetsCheckboxValue.EPC:
                return locationGrouping.epcAssets;
            case AssetsCheckboxValue.METER:
                return locationGrouping.meterAssets;
            case AssetsCheckboxValue.MISSING:
                return locationGrouping.missingDataAssets;
            case AssetsCheckboxValue.EPC + AssetsCheckboxValue.METER:
                return locationGrouping.epcAndMeter;
            case AssetsCheckboxValue.EPC + AssetsCheckboxValue.MISSING:
                return locationGrouping.epcAndMissingData;
            case AssetsCheckboxValue.METER + AssetsCheckboxValue.MISSING:
                return locationGrouping.meterAndMissingData;
            case AssetsCheckboxValue.EPC +
                AssetsCheckboxValue.METER +
                AssetsCheckboxValue.MISSING:
            default:
                return locationGrouping.allAssets;
        }
    }, [locationGrouping, filters.assets]);

    const isUsingExternalId = useMemo(
        () =>
            Boolean(filteredLocationsBySearchString?.some((l) => l.externalId)),
        [filteredLocationsBySearchString]
    );

    const downloadFileName = useMemo(() => {
        const activeYearAsDate = DateTime.fromObject({
            year: activeYear,
        }).toFormat("yyyy-MM-dd");

        const description = activeAssetGroup.name;
        const fileName = getFileToDownloadName(
            t,
            filters.assets,
            "AssetsList",
            undefined,
            {
                filteredAssets: activeLocations ? activeLocations.length : 0,
                unfilteredAssets: locationGrouping
                    ? locationGrouping.allAssets.length
                    : 0,
            },
            description || "",
            {
                from: activeYearAsDate,
                to: activeYearAsDate,
                dateContextType: DateContextType.YEAR,
            }
        );

        return fileName;
    }, [
        activeYear,
        filters.assets,
        activeLocations,
        locationGrouping,
        t,
        activeAssetGroup,
    ]);

    return (
        <Box>
            <Typography variant="h2" sx={{ mb: 2 }}>
                {t("assessmentOverview.assetList.header.title", "Your Assets")}
            </Typography>
            <Typography variant="h5" color="grey.400">
                {t(
                    "assessmentOverview.assetList.header.subHeader",
                    "YTD emissions are calculated until the previous day."
                )}
            </Typography>
            <Typography variant="h5" color="grey.400">
                {t(
                    "assessmentOverview.assetList.header.subSubHeader",
                    "All emissions calculations will be based on available meter data, unless selected otherwise in the filter."
                )}
            </Typography>
            <AssetListFilter
                filterState={filters}
                totalAssets={activeLocations.length}
                onFilterChange={handleFilterChange}
            />

            {loading && (
                <LoadingOverlay>
                    <Loading description={t("loading.title", "Loading")} />
                </LoadingOverlay>
            )}

            {dataLoader && (
                <AssetListOverview
                    downloadFileName={downloadFileName}
                    activeLocations={activeLocations}
                    chosenAssets={filters.assets}
                    isUsingExternalId={isUsingExternalId}
                />
            )}
        </Box>
    );
}

export default AssetListData;
