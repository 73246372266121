import { useState } from "react";
import { match } from "ts-pattern";

import RadioDropdown, {
    DropDownPerformanceType,
} from "components/AssetList/RadioDropdown";
import SearchFilter from "components/AssetList/SearchFilter";
import YearAndAssessmentTypeFilter from "components/YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";
import { AssessmentDataType } from "graphql-types/graphql";

import { S } from "./components.styles";
import { PerformanceFilter, QueryFilter } from "../helpers";

type Props = {
    queryFilter: QueryFilter;
    onFilterChange: (key: keyof QueryFilter, value: any) => void;
};

const emptyFilterState = {
    searchInput: "",
    dataType: AssessmentDataType.COMBINED,
    performance: PerformanceFilter.ALL,
};

export const Filters = (props: Props) => {
    const { onFilterChange } = props;

    const [filterState, setFilterState] = useState(emptyFilterState);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...filterState, searchInput: e.target.value });

        onFilterChange("inputString", e.target.value);
    };

    const handleDataTypeChange = (value: AssessmentDataType) => {
        setFilterState({ ...filterState, dataType: value });

        onFilterChange("dataType", value);
    };

    const handlePerformanceChange = (value: DropDownPerformanceType) => {
        const performance = match(value)
            .with(DropDownPerformanceType.ALL, () => PerformanceFilter.ALL)
            .with(
                DropDownPerformanceType.BELOW_BENCHMARK,
                () => PerformanceFilter.BELOW
            )
            .with(
                DropDownPerformanceType.ABOVE_BENCHMARK,
                () => PerformanceFilter.ABOVE
            )
            .exhaustive();

        setFilterState({ ...filterState, performance });
        onFilterChange("performance", performance);
    };

    const dropdownPerformance = match(filterState.performance)
        .with(PerformanceFilter.ALL, () => DropDownPerformanceType.ALL)
        .with(
            PerformanceFilter.BELOW,
            () => DropDownPerformanceType.BELOW_BENCHMARK
        )
        .with(
            PerformanceFilter.ABOVE,
            () => DropDownPerformanceType.ABOVE_BENCHMARK
        )
        .exhaustive();

    return (
        <S.Container>
            <SearchFilter
                inputValue={filterState.searchInput}
                onChange={handleInputChange}
            />

            <RadioDropdown
                selectedValue={dropdownPerformance}
                onChange={handlePerformanceChange}
            />

            <YearAndAssessmentTypeFilter
                activeType={filterState.dataType}
                onAssessmentTypeChange={handleDataTypeChange}
            />
        </S.Container>
    );
};
