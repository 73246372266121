import { Check } from "@mui/icons-material";
import { Box, TableRow } from "@mui/material";

import { S } from "../strategyDrawer.style";
import { RowData } from "../strategyDrawer.types";

type Props = {
    data: RowData;
    index?: number;
};

export const StrategyRow = (props: Props) => {
    const { data, index } = props;

    return (
        <TableRow>
            <S.Cell>
                {data.kpi}
                {index && ` #${index}`}
            </S.Cell>
            <S.Cell>{data.current}</S.Cell>
            <S.Cell>{data.target}</S.Cell>
            <S.Cell sx={{ opacity: 0.6 }}>{data.unit}</S.Cell>
            <S.Cell>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {data.completed === true ? (
                        <Check fontSize="inherit" />
                    ) : (
                        <span style={{ opacity: 0.6 }}>{data.completed}</span>
                    )}
                </Box>
            </S.Cell>
        </TableRow>
    );
};
