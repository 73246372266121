import { Box, FormControl, MenuItem, Select, styled } from "@mui/material";
import React, {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

import { BenchmarkType, Benchmark } from "graphql-types/graphql";
import { BenchmarksContext } from "utils/benchmarksContext";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    minWidth: "175px",

    "& .MuiSelect-icon": {
        fontSize: theme.spacing(4),
    },

    "& .MuiSelect-select.MuiSelect-select": {
        padding: 0,
        fontSize: theme.spacing(3),
        color: theme.palette.grey[400],
        position: "relative",
        textTransform: "capitalize",
    },

    "& .MuiInput-underline": {
        "&:before, &:after": {
            borderBottom: "none",
        },
    },
}));

const SubHeaderBox = styled(Box)(({ theme }) => ({
    fontSize: theme.spacing(3),
    color: theme.palette.grey[400],
    userSelect: "none",
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: theme.spacing(3),
    textTransform: "capitalize",
    padding: "0.5rem",
}));

type BenchmarkContextType = {
    benchmarks: Benchmark[];
    activeBenchmark: Benchmark | null;
    setActiveBenchmarkId: (benchmarkId: string) => void;
};

export const ActiveBenchmarkContext = createContext<BenchmarkContextType>({
    benchmarks: [],
    activeBenchmark: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setActiveBenchmarkId: (benchmarkId: string) => {},
});

export const ActiveBenchmarkProvider = (props: PropsWithChildren) => {
    const { children } = props;

    const { benchmarks } = useContext(BenchmarksContext);

    const [benchmarkId, setActiveBenchmarkId] = useState(
        benchmarks.length > 0 ? benchmarks[0].id : ""
    );

    const activeBenchmark =
        benchmarks.find((b) => b.id === benchmarkId) ?? benchmarks[0] ?? null;

    return (
        <ActiveBenchmarkContext.Provider
            value={{ benchmarks, activeBenchmark, setActiveBenchmarkId }}
        >
            {children}
        </ActiveBenchmarkContext.Provider>
    );
};

const BenchmarkSelect = () => {
    const { t } = useTranslation();

    const { benchmarks, activeBenchmark, setActiveBenchmarkId } = useContext(
        ActiveBenchmarkContext
    );

    const getBenchmarkText = useCallback(
        (benchmarkType: BenchmarkType, countryCode: string | null) => {
            switch (benchmarkType) {
                case BenchmarkType.BASELINE:
                    return t(
                        "benchmark.portfolioBenchmark",
                        "Portfolio Benchmark"
                    );
                case BenchmarkType.MARKET:
                    return countryCode
                        ? t(
                              "benchmark.countryBenchmarkCountryCode",
                              "Country Benchmark ({{countryCode}})",
                              { countryCode }
                          )
                        : t("benchmark.countryBenchmark", "Country Benchmark");
            }
        },
        [t]
    );

    if (benchmarks.length < 2) {
        const benchmark = benchmarks[0];
        const benchmarkType = benchmark?.type ?? BenchmarkType.MARKET;
        const countryCode = benchmark?.countryCode ?? null;

        return (
            <SubHeaderBox>
                {getBenchmarkText(benchmarkType, countryCode)}
            </SubHeaderBox>
        );
    }

    const benchmarkOptions = benchmarks.map((benchmark) => {
        const { type, countryCode } = benchmark;
        if (type === BenchmarkType.BASELINE) {
            return {
                value: benchmark.id,
                label: t("benchmark.portfolioBenchmark", "Portfolio Benchmark"),
            };
        }

        return {
            value: benchmark.id,
            label: t(
                "benchmark.countryBenchmarkCountryCode",
                "Country Benchmark ({{countryCode}})",
                { countryCode }
            ),
        };
    });

    return (
        <StyledFormControl
            onBlur={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
        >
            <Select
                variant="standard"
                value={activeBenchmark?.id}
                onChange={(e) => setActiveBenchmarkId(e.target.value)}
            >
                {benchmarkOptions.map((benchmark) => (
                    <StyledMenuItem
                        key={benchmark.value}
                        value={benchmark.value}
                    >
                        {benchmark.label}
                    </StyledMenuItem>
                ))}
            </Select>
        </StyledFormControl>
    );
};

export default BenchmarkSelect;
