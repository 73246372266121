import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { MonthChartData } from "components/PortfolioDashboard/types";
import { OrgEmissionSummaryFragment } from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedConvertedGramsToMassUnit } from "../charts.helper";

interface IntensityEmissionByMonth {
    emissionIntensity: number;
    month: number;
    assetCount: number;
    totalArea: number;
}

export const getIntensityEmissionByMonth = (
    orgEmissionSummaries: OrgEmissionSummaryFragment[]
): IntensityEmissionByMonth[] => {
    const summariesGrouped = _.chain(orgEmissionSummaries)
        .filter((e) => Boolean(e.ownedArea))
        .groupBy(
            (emissionSummary) => DateTime.fromISO(emissionSummary.from).month
        )
        .map((assetEmissions, month) => {
            const totalEmission = _.sumBy(
                assetEmissions,
                (assetEmission) => assetEmission.ownedEmissionWithArea || 0
            );
            const totalArea = _.sumBy(
                assetEmissions,
                (assetEmission) => assetEmission.ownedArea || 0
            );
            return {
                totalArea,
                assetCount: assetEmissions.length,
                month: Number(month),
                emissionIntensity: totalEmission / totalArea,
            };
        })
        .sortBy((assessmentsByMonth) => assessmentsByMonth.month)
        .value();

    return summariesGrouped;
};

export const getIntensityEmissionByMonthChartData = (
    intensityEmissionsByMonth: IntensityEmissionByMonth[],
    activeYear: number,
    t: TFunction
) => {
    return intensityEmissionsByMonth.map(
        ({
            emissionIntensity,
            month,
            totalArea,
            assetCount,
        }): MonthChartData => {
            const date = DateTime.local(activeYear, month, 1);

            return {
                value: getConvertedGramsToMassUnit(
                    emissionIntensity,
                    UnitMass.KILOGRAM
                ),
                label: date.monthShort,
                title: t(
                    "portfolioDashboard.hoverBox.portIntensity",
                    "Portfolio Intensity"
                ),
                header: date.monthLong,
                formattedValue: getFormattedConvertedGramsToMassUnit(
                    emissionIntensity,
                    UnitMass.KILOGRAM,
                    t,
                    true
                ),
                assetCount,
                totalArea,
            };
        }
    );
};
