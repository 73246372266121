import { TFunction } from "i18next";
import _ from "lodash";
import { resolvePath } from "react-router-dom";

import {
    DataQualityFragment,
    AssessmentVertical,
    AssetListLocationFragment,
    Benchmark,
    IntegrationType,
    AssessmentDataType,
} from "graphql-types/graphql";
import { DASH_SEPARATOR, parseJSON } from "utils/strings.helpers";

import { FilterState, emptyFilterState } from "./AssetListFilter";
import { AssetType, PerformanceAgainstBenchmarkType } from "./AssetListtypes";
import { AssetsCheckboxValue } from "./AssetsCheckbox";
import { DisplayLocation } from "./assetTypes";
import { DropDownPerformanceType } from "./RadioDropdown";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "../../utils/report.helpers";
import { UnitMass } from "../AssessmentStats/types";
import { getClassificationsFromAssessmentSummariesInSelectedYear } from "../Cells/EpcClassificationsCell.helper";
import {
    formatDataQualityToDataQualityItems,
    getDataQualityTitle,
} from "../DataQuality/DataQuality.helpers";

export const formatPercentage = (performance: number) => {
    return _.round(performance - 100);
};

const getPerformance = (
    benchmark: Benchmark | null,
    benchmarkPerformances: AssetListLocationFragment["benchmarkPerformances"],
    assetColumnName: AssetType
) => {
    if (!benchmark) {
        return null;
    }

    const dataType =
        assetColumnName === AssetType.epc
            ? AssessmentDataType.ESTIMATE
            : AssessmentDataType.COMBINED;

    const { performance } = _.chain(benchmarkPerformances)
        .find(
            (bp) => bp.benchmarkId === benchmark.id && bp.dataType === dataType
        )
        .value() || { performance: null };

    return performance * 100;
};

export const mapLocations = (
    locations: AssetListLocationFragment[],
    selectedYear: number,
    t: TFunction,
    performanceAgainstBenchmarkCallback: PerformanceAgainstBenchmarkType,
    benchmarkData: Benchmark | null,
    assetColumnName: AssetType
) => {
    return _.chain(locations)
        .map((location): DisplayLocation | null => {
            const emissionPerArea = getConvertedGramsToMassUnit(
                _.chain(location.assessmentSummaries)
                    .sumBy(
                        (a) =>
                            a.emissionDataGrams.emissionPerArea[assetColumnName]
                                .ownedEmission || 0
                    )
                    .value(),
                UnitMass.KILOGRAM
            );

            const totalEmission = getConvertedGramsToMassUnit(
                _.chain(location.assessmentSummaries)
                    .sumBy(
                        (a) =>
                            a.emissionDataGrams.emission[assetColumnName]
                                .ownedEmission || 0
                    )
                    .value(),
                UnitMass.KILOGRAM
            );

            const classifications =
                getClassificationsFromAssessmentSummariesInSelectedYear(
                    location.assessmentSummaries,
                    selectedYear
                );

            const dataQualityFromSelectedYear = location.dataQuality.find(
                (d) => new Date(d.from).getFullYear() === selectedYear
            );

            const performance = getPerformance(
                benchmarkData,
                location.benchmarkPerformances,
                assetColumnName
            );

            const filteredLocation =
                performanceAgainstBenchmarkCallback(performance);

            if (!filteredLocation) {
                return null;
            }
            const performanceBenchmarkPercentage = performance
                ? `${formatPercentage(performance)}`
                : DASH_SEPARATOR;

            const hasCustomName = !!location.name;
            const displayName = location.displayName || DASH_SEPARATOR;
            const area = location.ownedArea
                ? formatNumberLocale(location.ownedArea, t, { max: 0 })
                : DASH_SEPARATOR;
            const showAreaNotFoundMessage = !location.ownedArea;

            const verticals = location.assessmentSummaries.flatMap(
                ({ vertical, sources }) => {
                    if (!dataQualityFromSelectedYear) return [];

                    const { highQuality, mediumQuality, lowQuality } =
                        dataQualityFromSelectedYear;

                    const verticalSources = sources.filter(
                        (s) =>
                            highQuality.flatMap((d) => d.sources).includes(s) ||
                            mediumQuality
                                .flatMap((d) => d.sources)
                                .includes(s) ||
                            lowQuality.flatMap((d) => d.sources).includes(s)
                    ) as IntegrationType[];

                    return verticalSources.length
                        ? { vertical, sources: verticalSources }
                        : [];
                }
            );

            return {
                ...location,
                hasCustomName,
                names: displayName,
                verticals,
                classifications,
                dataQualityFromSelectedYear,
                totalEmission: totalEmission
                    ? formatNumberLocale(totalEmission, t)
                    : DASH_SEPARATOR,
                emissionPerSize: emissionPerArea
                    ? formatNumberLocale(emissionPerArea, t)
                    : DASH_SEPARATOR,
                showAreaNotFoundMessage,
                area: area,
                performance: performanceBenchmarkPercentage,
                performanceLabel: performanceBenchmarkPercentage,
                link: resolvePath({
                    pathname: `/location-overview/${location.id}`,
                }),
            };
        })
        .compact()
        .value();
};

export const filterLocationByString = (
    l: AssetListLocationFragment,
    queryString: string
) => {
    if (queryString === "") {
        return true;
    }

    const lowerCaseSearchString = queryString.toLowerCase();
    return (
        l.longAddress
            ?.toLowerCase()
            .split(",")[0]
            .includes(lowerCaseSearchString) ||
        l.externalId?.toLowerCase().includes(lowerCaseSearchString) ||
        l.name?.toLowerCase().includes(lowerCaseSearchString) ||
        l.displayName?.toLowerCase().includes(lowerCaseSearchString)
    );
};

export const filterLocationByVertical = (
    l: AssetListLocationFragment,
    verticals: AssessmentVertical[]
) => {
    const locationVerticals = l.assessmentSummaries.map((a) => a.vertical);

    return (
        verticals.length === 0 ||
        verticals.every((v) => locationVerticals.includes(v))
    );
};

export const formatDataQualityColumn = (
    value: DataQualityFragment,
    t: TFunction
) => {
    if (!value) {
        return "";
    }

    const items = formatDataQualityToDataQualityItems(value, t);

    const textItems = items.map(
        ({ type, sources }) =>
            `${getDataQualityTitle(type, false, t)}: ${t(
                "common.lists",
                "{{list, list}}",
                {
                    list: sources.map((s) => s.name),
                    type: "unit",
                }
            )}`
    );

    return textItems.join(" / ");
};

export const isValidFilter = (str: string, filterKey: keyof FilterState) => {
    const value = parseJSON(str);

    if (!value) return false;

    switch (filterKey) {
        case "assets":
            return (
                Array.isArray(value) &&
                value.every((v) =>
                    Object.values(AssetsCheckboxValue).includes(v)
                )
            );
        case "verticals":
            return (
                Array.isArray(value) &&
                value.every((v) =>
                    Object.values(AssessmentVertical).includes(v)
                )
            );
        case "performance":
            return Object.values(DropDownPerformanceType).includes(
                value as DropDownPerformanceType
            );
        case "searchInput":
            return typeof value === "string";
    }
};

export const getFormattedSearchParams = (filters: FilterState) => {
    const searchParams = {} as Record<string, string>;

    Object.keys(filters).forEach((key) => {
        const k = key as keyof FilterState;
        const value = filters[k];

        if (_.isEqual(value, emptyFilterState[k])) return;

        searchParams[key] = JSON.stringify(value);
    });

    return searchParams;
};
