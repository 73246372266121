import { useLazyQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useOrganizationContext } from "context";
import {
    AssetGroupFilterCategory,
    CrremCategory,
    GetAssetRiskPreviewQuery,
    GetAssetRiskPreviewQueryVariables,
} from "graphql-types/graphql";
import { FilterItemOption, FilterItemOptions, filtersState } from "utils";

import { GET_ASSET_RISK_PREVIEW } from "./asset-risk.query";
import { Filters, Chart, StrategyDrawer } from "./components";
import { StrategyLegend } from "./components/Legend";
import { S } from "./page.styles";
import { AssetRisk } from "./strategyPage.types";

const currentYear = new Date().getFullYear();

export const StrategyPage = () => {
    const organization = useOrganizationContext();

    const [assetDrawerState, setAssetDrawerState] = useState<{
        isOpen: boolean;
        data?: AssetRisk;
    }>({
        isOpen: false,
    });

    const [assets, setAssets] = useState<AssetRisk[]>([]);
    const [filters, setFilters] = useState<FilterItemOptions>([
        {
            category: AssetGroupFilterCategory.CONSTRUCTIONYEAR,
            values: [],
        },
        {
            category: AssetGroupFilterCategory.PROPERTYSIZE,
            values: [],
        },
        {
            category: AssetGroupFilterCategory.CRREMPROPERTYTYPES,
            values: [
                {
                    key: "OFFICE",
                    value: CrremCategory.OFFICE,
                },
            ],
        },
    ]);

    const [getAssetRiskPreview] = useLazyQuery<
        GetAssetRiskPreviewQuery,
        GetAssetRiskPreviewQueryVariables
    >(GET_ASSET_RISK_PREVIEW);

    function onFilterChange(filter: FilterItemOption) {
        const hasFilter = filters.some((currentFilter) => {
            return currentFilter.category === filter.category;
        });

        if (!hasFilter) {
            setFilters([...filters, filter]);
            return;
        }

        const pendingFilters = filters.map((currentFilter) => {
            if (currentFilter.category === filter.category) {
                const values = filter.values;
                return {
                    ...currentFilter,
                    values,
                };
            }
            return currentFilter;
        });

        setFilters(pendingFilters);
    }

    function onDrawerClose() {
        setAssetDrawerState((prevState) => ({
            ...prevState,
            isOpen: false,
        }));
    }

    function onSelectedAsset(asset: AssetRisk) {
        setAssetDrawerState({ isOpen: true, data: asset });
    }

    useEffect(() => {
        if (filters.length === 0) {
            return;
        }

        getAssetRiskPreview({
            variables: {
                input: filtersState.toJSON(filters),
                filter: {
                    from: {
                        between: {
                            lower: `${organization?.baselineYear}`,
                            upper: `${currentYear + 1}`,
                        },
                    },
                },
            },
        }).then((response) => {
            setAssets(
                (response.data?.getAssetGroupPreview ?? []) as AssetRisk[]
            );
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <S.Page height="100vh" display="flex" flexDirection="column">
            <Typography variant="h1">SBTi decarbonisation path</Typography>
            <Typography variant="subtitle1">
                Get a full overview of whether or not your full portfolio and
                individual assets are on track when it comes to reduction plans.
            </Typography>
            <S.Content>
                <Typography variant="h4">Portfolio path overview</Typography>
                <Typography variant="body1">
                    Pick the relevant assets and compare their path to the CRREM
                    and National Legacy benchmarks to see if they are performing
                    well enough or if they are at risk of stranding — download
                    the graph, and use the path overview to guide your strategic
                    decision making.
                </Typography>
                <S.Filters>
                    <Filters filters={filters} onChange={onFilterChange} />
                </S.Filters>
                <Chart assets={assets} onSelectedAsset={onSelectedAsset} />
                <StrategyLegend
                    filters={filters.filter(
                        (filter) =>
                            filter.category ===
                            AssetGroupFilterCategory.CRREMPROPERTYTYPES
                    )}
                />
            </S.Content>
            <StrategyDrawer
                isOpen={assetDrawerState.isOpen}
                onClose={onDrawerClose}
                asset={assetDrawerState.data}
            />
        </S.Page>
    );
};
