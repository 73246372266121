import { MenuItem, Select, styled } from "@mui/material";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import { DataSourceStatusFilter } from "containers/AssetPage/AssetDataOverview/types/dataSourceTypes";
import { convertToRem } from "utils/maths";

import { getStatusColor } from "../../automatic-sources-table.helpers";

export const S = {
    Select: styled(Select)(() => ({
        background: "#fff",
        width: convertToRem(150),
    })) as unknown as typeof Select,

    MenuItem: styled(MenuItem)<{ status?: DataSourceStatusFilter }>(
        ({ theme, status }) => {
            const statusColor =
                status && getStatusColor(status as unknown as DataSourceStatus);

            return {
                "&.MuiMenuItem-root": {
                    borderLeftWidth: status ? 5 : 0,
                    borderLeftStyle: "solid",
                    borderLeftColor:
                        statusColor && theme.palette[statusColor].main,
                },
            };
        }
    ),
};
