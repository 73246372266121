import { gql } from "@apollo/client";

export const PERFORMANCE_LOCATION = gql`
    fragment PerformanceLocation on Location {
        id
        displayName
        ownedArea
        externalId
        assetAlerts(
            filter: {
                from: { in: $year }
                type: { eq: EPC_EXPIRED_IN_CURRENT_YEAR }
            }
        ) {
            id
        }
        benchmarkPerformances(
            filter: {
                from: { in: $year }
                dataType: { eq: $dataType }
                benchmarkId: { eq: $benchmarkId }
            }
        ) {
            to
            from
            dataType
            benchmarkType
            performance
            ownedEmission
        }
    }
`;

export const YEARLY_PERFORMANCE_QUERY = gql`
    query GetYearlyPerformance(
        $dataType: AssessmentDataType!
        $benchmarkId: ID
        $year: [DateTime!]
        $assetGroupId: ID
    ) {
        getAssets(assetGroupId: $assetGroupId) {
            ...PerformanceLocation
        }
    }
    ${PERFORMANCE_LOCATION}
`;
