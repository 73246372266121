import { TFunction } from "i18next";
import { chain, meanBy, round } from "lodash";

import {
    roundTo,
    BENCHMARK_LABEL,
} from "components/PortfolioDashboard/helpers/chartHelpers";
import { getBenchmark } from "components/PortfolioDashboard/helpers/utilities";
import { xAxisBandConfig } from "glue/Chart/chart.types";
import {
    Benchmark,
    AssessmentDataType,
    PerformanceLocationFragment,
} from "graphql-types/graphql";
import { gramsToKg } from "utils/report.helpers";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import {
    BuildingData,
    PerformanceBarSeries,
} from "./benchmarkPerformanceChart.types";
import { PerformanceAxisLabel } from "./components/benchmarkPerformanceAxisLabel";

const getLabel = (percentage: number) => {
    if (percentage < 100) {
        return `${100 - percentage}`;
    } else if (percentage === 100) {
        return BENCHMARK_LABEL;
    } else {
        return percentage;
    }
};

export const formatBenchmarkPerformanceLocation = (
    locations: PerformanceLocationFragment[],
    dataType: AssessmentDataType
): BuildingData[] => {
    return locations.flatMap((location) => {
        const { displayName, ownedArea, externalId, benchmarkPerformances } =
            location;

        const hasExpiredEpc = location.assetAlerts.length > 0;

        return benchmarkPerformances
            .filter((performance) => performance.dataType === dataType)
            .map(({ ownedEmission, performance }) => {
                const emissionInKg = gramsToKg(ownedEmission);

                return {
                    hasExpiredEpc,
                    link: location.id,
                    address: displayName || DASH_SEPARATOR,
                    externalId: externalId || DASH_SEPARATOR,
                    emission: emissionInKg,
                    emissionPerArea: emissionInKg / (ownedArea ?? 1),
                    performance: round(performance * 100),
                };
            });
    });
};

export const getBenchmarkPerformanceChartData = (
    benchmark: Benchmark,
    activeDataType: AssessmentDataType,
    benchmarkPerformance: BuildingData[],
    t: TFunction
): {
    benchmarkValue: number | null;
    sidePanelData: BuildingData[][];
    series: PerformanceBarSeries[];
    xAxis: xAxisBandConfig[];
} => {
    const benchmarkValue = getBenchmark(activeDataType, benchmark);

    const groupedPerformance = chain(benchmarkPerformance).groupBy(
        ({ performance }) => round(roundTo(performance, 5))
    );

    const data = groupedPerformance
        .map((performance) => performance.length)
        .value();
    const sidePanelData = groupedPerformance
        .map((performance) => performance)
        .value();
    const emission = groupedPerformance
        .map((performance) => meanBy(performance, "emissionPerArea"))
        .value();

    const xAxisData = groupedPerformance.keys().value();

    return {
        benchmarkValue,
        sidePanelData,
        series: [
            {
                type: "bar",
                data,
                emission,
                label: t(
                    "portfolioDashboard.hoverBox.averageEmissionPerArea",
                    "Average Emission"
                ),
            },
        ],
        xAxis: [
            {
                disableTicks: true,
                scaleType: "band",
                data: xAxisData,
                categoryGapRatio: 0.1,
                slots: {
                    axisTickLabel: PerformanceAxisLabel,
                },
                valueFormatter: (value) => `${getLabel(value)}%`,
            },
        ],
    };
};
