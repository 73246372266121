import { useMutation } from "@apollo/client";
import { Box, Button, Stack, MenuItem, Paper, Alert } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { startCase } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useState, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { changeLogQueryName } from "components/ChangeLog/changeLogQueries";
import BasicDatePicker from "components/DatePicker/DatePicker";
import {
    NotificationBar,
    NotificationType,
} from "components/Notifications/NotificationBar";
import WarningPopover from "components/Popovers/WarningPopover";
import {
    ChangeLogEventType,
    GetAllAssetDetailsQuery,
    LocationManagementType,
    PropertyType,
    UpdateAssetMasterDataMutation,
    UpdateAssetMasterDataMutationVariables,
} from "graphql-types/graphql";
import { getDirtyValues } from "utils/forms.utils";

import {
    MasterDataEditableFields,
    getFormattedPropertyType,
    getMasterDataUpdateNotification,
    updateAssetDetailsCache,
} from "./AssetDetails.helpers";
import {
    ChangeLogEditButton,
    getTextFieldStyle,
    TextFieldBox,
    TextFieldWithLabel,
} from "./components";
import { UPDATE_MASTER_DATA } from "./queries/assetMasterDataQuery";

const AssetMasterData = ({
    asset,
    onChangeLogOpenClick,
    editsCount,
}: {
    asset: GetAllAssetDetailsQuery["location"];
    onChangeLogOpenClick: () => void;
    editsCount: number;
}) => {
    const { t } = useTranslation();
    const { useAddingPropertiesFlow } = useFlags();

    const [updateMasterData] = useMutation<
        UpdateAssetMasterDataMutation,
        UpdateAssetMasterDataMutationVariables
    >(UPDATE_MASTER_DATA, {
        update(cache, _, { variables }) {
            if (!variables?.input.update) return;

            updateAssetDetailsCache(cache, variables.input.update, asset.id);

            // Evict the asset and organization change log from the cache to force a refetch
            cache.evict({
                id: "ROOT_QUERY",
                fieldName: changeLogQueryName,
                args: {
                    eventType: ChangeLogEventType.ASSET_UPDATED,
                    recordId: asset.id,
                },
            });

            cache.evict({
                id: "ROOT_QUERY",
                fieldName: changeLogQueryName,
                args: { eventType: ChangeLogEventType.ASSET_UPDATED },
            });
        },
    });

    const [isEditMode, setEditMode] = useState(false);

    const defaultValues = useMemo(
        () => ({
            name: asset.displayName || "",
            externalId: asset.externalId || "",
            buildingArea: asset.buildingArea,
            ownership: asset.ownership,
            propertyType: asset.propertyType,
            management: asset.management,
            purchaseDate: asset.purchaseDate
                ? DateTime.fromISO(asset.purchaseDate)
                : null,
            saleDate: asset.saleDate ? DateTime.fromISO(asset.saleDate) : null,
            legalOwner: asset.legalOwner || "",
        }),
        [asset]
    );

    const [notificationType, setNotificationType] =
        useState<NotificationType | null>(null);
    const [openNotification, setOpenNotification] = useState<boolean>(
        !!notificationType
    );

    const handleNotificationChange = (newOpen: boolean) => {
        setOpenNotification(newOpen);
    };

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors, isDirty, isSubmitSuccessful, dirtyFields },
    } = useForm<MasterDataEditableFields>({
        defaultValues,
    });

    const submittedData = watch();

    const onSave = useCallback(
        (data: MasterDataEditableFields) => {
            setEditMode(false);

            const updatedFields = getDirtyValues(dirtyFields, data);

            updateMasterData({
                variables: {
                    input: {
                        id: asset.id,
                        update: updatedFields,
                    },
                },
            })
                .then(() => {
                    setNotificationType(NotificationType.SUCCESS);
                    setOpenNotification(true);
                })
                .catch(() => {
                    setNotificationType(NotificationType.ERROR);
                    setOpenNotification(true);
                });
        },
        [setEditMode, updateMasterData, asset.id, dirtyFields]
    );

    const onCancel = useCallback(() => {
        reset(defaultValues);
        setEditMode(false);
    }, [defaultValues, reset, setEditMode]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(submittedData);
        }
    }, [isSubmitSuccessful, submittedData, reset]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <form onSubmit={handleSubmit(onSave)}>
            <Paper>
                <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                        <TextFieldWithLabel
                            value={value}
                            onChange={onChange}
                            disabled={!isEditMode}
                            label={t("assetDetailsComponent.name", "Name")}
                            error={Boolean(errors.name)}
                            helperText={t(
                                "assetDetailsComponent.masterData.errors.displayName",
                                "An asset name is required"
                            )}
                        />
                    )}
                />

                <TextFieldWithLabel
                    disabled
                    name="numberOfProperties"
                    value={asset.propertyCount}
                    label={t(
                        "assetDetailsComponent.numberOfProperties",
                        "No. of Properties"
                    )}
                />

                <Controller
                    control={control}
                    name="externalId"
                    render={({ field: { value, onChange } }) => (
                        <TextFieldWithLabel
                            value={value}
                            onChange={onChange}
                            disabled={!isEditMode}
                            label={t(
                                "assetDetailsComponent.internalID",
                                "Internal ID"
                            )}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="buildingArea"
                    rules={{
                        validate: (value) => value === null || value >= 0,
                    }}
                    render={({ field: { value, onChange } }) => (
                        <TextFieldWithLabel
                            value={value || ""}
                            onChange={(event) =>
                                onChange(parseInt(event.target.value))
                            }
                            disabled={!isEditMode}
                            type="number"
                            tooltipText={t(
                                "assetDetailsComponent.totalAreaWarning",
                                "The total area is automatically aggregated from public data sources. If you decide to edit this field, please also update your asset data on the public registries."
                            )}
                            inputProps={{ step: 0.5 }}
                            endAdornment={t("common.units.area.m2", "m²")}
                            label={t(
                                "assetDetailsComponent.totalArea",
                                "Total Area"
                            )}
                            error={Boolean(errors.buildingArea)}
                            helperText={t(
                                "assetDetailsComponent.masterData.errors.totalArea",
                                "Total area must be empty or a positive number"
                            )}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="ownership"
                    rules={{
                        validate: (value) =>
                            value === null ||
                            (value >= 0 && value <= 100 && value % 1 === 0),
                    }}
                    render={({ field: { value, onChange } }) => (
                        <TextFieldWithLabel
                            value={value || ""}
                            onChange={(event) =>
                                onChange(parseInt(event.target.value))
                            }
                            disabled={!isEditMode}
                            type="number"
                            endAdornment={t(
                                "common.units.percentage.label",
                                "%"
                            )}
                            label={t(
                                "assetDetailsComponent.ownership",
                                "Ownership"
                            )}
                            error={Boolean(errors.ownership)}
                            helperText={t(
                                "assetDetailsComponent.masterData.errors.ownership",
                                "Ownership must be empty or a whole number percentage"
                            )}
                        />
                    )}
                />

                <TextFieldWithLabel
                    disabled
                    endAdornment={t("common.units.area.m2", "m²")}
                    value={asset.ownedArea}
                    label={t(
                        "assetDetailsComponent.totalOwnedArea",
                        "Total Owned Area"
                    )}
                />

                {useAddingPropertiesFlow && (
                    <Controller
                        name="propertyType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextFieldWithLabel
                                select
                                disabled={!isEditMode}
                                value={value || ""}
                                onChange={onChange}
                                label={t(
                                    "assetDetailsComponent.assetType",
                                    "Asset Type"
                                )}
                            >
                                {(
                                    Object.keys(PropertyType) as PropertyType[]
                                ).map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {getFormattedPropertyType(value, t)}
                                    </MenuItem>
                                ))}
                            </TextFieldWithLabel>
                        )}
                    />
                )}

                {!useAddingPropertiesFlow && (
                    <Controller
                        control={control}
                        name="propertyType"
                        render={({ field: { onChange, value } }) => (
                            <TextFieldWithLabel
                                select
                                disabled={!isEditMode}
                                value={value || ""}
                                onChange={onChange}
                                label={t(
                                    "assetDetailsComponent.propertyType",
                                    "Property Type"
                                )}
                            >
                                {(
                                    Object.keys(PropertyType) as PropertyType[]
                                ).map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {getFormattedPropertyType(value, t)}
                                    </MenuItem>
                                ))}
                            </TextFieldWithLabel>
                        )}
                    />
                )}

                <Controller
                    control={control}
                    name="purchaseDate"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                        <TextFieldBox>
                            {t(
                                "assetDetailsComponent.purchaseDate",
                                "Purchase Date"
                            )}
                            <BasicDatePicker
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        sx: getTextFieldStyle(!isEditMode),
                                    },
                                }}
                                disabled={!isEditMode}
                                disableOpenPicker={!isEditMode}
                                value={value}
                                onChange={onChange}
                                isClearAllowed
                            />
                        </TextFieldBox>
                    )}
                />

                <Controller
                    control={control}
                    name="saleDate"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                        <TextFieldBox>
                            <Box display="flex">
                                {t(
                                    "assetDetailsComponent.saleDate",
                                    "Sale Date"
                                )}
                                <WarningPopover
                                    element={t(
                                        "assetDetailsComponent.saleDateWarning",
                                        "When a sale date is added, the emissions data will no longer be included in the portfolio from that date forward."
                                    )}
                                />
                            </Box>
                            <BasicDatePicker
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        sx: getTextFieldStyle(!isEditMode),
                                    },
                                }}
                                disableOpenPicker={!isEditMode}
                                disabled={!isEditMode}
                                value={value}
                                onChange={onChange}
                                isClearAllowed
                            />
                        </TextFieldBox>
                    )}
                />

                <Controller
                    control={control}
                    name="legalOwner"
                    render={({ field: { value, onChange } }) => (
                        <TextFieldWithLabel
                            value={value}
                            onChange={onChange}
                            disabled={!isEditMode}
                            label={t(
                                "assetDetailsComponent.legalOwner",
                                "Legal Owner"
                            )}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="management"
                    render={({ field: { onChange, value } }) => (
                        <TextFieldWithLabel
                            select
                            value={value || ""}
                            onChange={onChange}
                            disabled={!isEditMode}
                            label={t(
                                "assetDetailsComponent.management",
                                "Administration"
                            )}
                        >
                            {(
                                Object.keys(
                                    LocationManagementType
                                ) as LocationManagementType[]
                            ).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {startCase(value.toLowerCase())}
                                </MenuItem>
                            ))}
                        </TextFieldWithLabel>
                    )}
                />

                {isEditMode && (
                    <Alert style={{ padding: "0.5em 1em" }} severity="warning">
                        {t(
                            "assetDetailsComponent.masterData.warning",
                            "All edits to master data will be reflected in your emission calculations the next business day"
                        )}
                    </Alert>
                )}

                <NotificationBar
                    notificationType={notificationType}
                    message={getMasterDataUpdateNotification(
                        notificationType,
                        t
                    )}
                    open={openNotification}
                    setOpen={handleNotificationChange}
                />
            </Paper>

            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    padding: 3,
                }}
            >
                <Box display="flex" alignItems="center">
                    <ChangeLogEditButton
                        editsCount={editsCount}
                        onClick={onChangeLogOpenClick}
                    />
                </Box>

                <Box justifyContent="flex-end">
                    {!isEditMode && (
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setEditMode(true)}
                        >
                            {t("settings.edit", "Edit")}
                        </Button>
                    )}

                    {isEditMode && (
                        <>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={onCancel}
                            >
                                {t("settings.cancel", "Cancel")}
                            </Button>
                            <Button
                                type="submit"
                                sx={{ marginLeft: 4 }}
                                disabled={!isDirty}
                                color="primary"
                                variant={isDirty ? "contained" : "outlined"}
                            >
                                {t("settings.saveChanges", "Save Changes")}
                            </Button>
                        </>
                    )}
                </Box>
            </Stack>
        </form>
    );
};

export default AssetMasterData;
