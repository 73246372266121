import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";
import { OrgEmissionSummaryFragment } from "graphql-types/graphql";

import { MonthlyTotalEmissionTooltip } from "./components/MonthlyTotalEmissionTooltip";
import { getTotalEmissionSummariesEmissionByMonth } from "./monthlyTotalEmissionsChart.helpers";
import { getMonthlyChartData } from "../helpers/monthlyChart.helpers";
import {
    AssetEmission,
    AssetWithAreaInformation,
} from "../types/monthlyChart.types";

type Props = {
    assets?: AssetWithAreaInformation[];
    orgEmissionSummaries: OrgEmissionSummaryFragment[];
};

export const MonthlyTotalEmissionsChart = (props: Props) => {
    const { assets = [], orgEmissionSummaries } = props;

    const { t } = useTranslation();
    const { activeYear } = useSelectedYearContext();

    const { series, xAxis, yAxis } = useMemo(() => {
        const emissionsByMonth = getTotalEmissionSummariesEmissionByMonth(
            assets,
            orgEmissionSummaries
        );

        return getMonthlyChartData<AssetEmission>(
            emissionsByMonth,
            "emission",
            t("portfolioDashboard.hoverBox.totalEmissions", "Total Emissions"),
            activeYear,
            t,
            false
        );
    }, [assets, orgEmissionSummaries, activeYear, t]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            customTooltip={MonthlyTotalEmissionTooltip}
        />
    );
};
