import { styled, TableCell } from "@mui/material";

export const S = {
    Cell: styled(TableCell)(({ theme }) => ({
        padding: 0,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    })),
    Head: styled(TableCell)(({ theme }) => ({
        padding: 0,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        fontWeight: "bold",
    })),
};
