import { Outlet } from "react-router-dom";

import { Page } from "glue";

const AssessmentRoute = () => {
    return (
        <Page>
            <Outlet />
        </Page>
    );
};

export default AssessmentRoute;
