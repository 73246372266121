import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { MonthChartData } from "components/PortfolioDashboard/types";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedConvertedGramsToMassUnit } from "../charts.helper";

export interface AssetAverageByMonth {
    emissionPerArea: number;
    month: number;
    assetArea: number;
}

interface AverageEmissionByMonth {
    emissionPerArea: number;
    month: number;
    assetCount: number;
    totalArea: number;
}

export const getAverageEmissionByMonth = (
    assetAveragesByMonth: AssetAverageByMonth[]
): AverageEmissionByMonth[] => {
    const assessmentsGroupedByMonth = _.chain(assetAveragesByMonth)
        .groupBy((assetAverage) => assetAverage.month)
        .map((assetAverages, month) => {
            const emissionPerArea = _.meanBy(
                assetAverages,
                (assetAverage) => assetAverage.emissionPerArea
            );
            const totalArea = _.sumBy(
                assetAverages,
                (assetAverage) => assetAverage.assetArea
            );
            return {
                totalArea,
                assetCount: assetAverages.length,
                month: Number(month),
                emissionPerArea: emissionPerArea,
            };
        })
        .sortBy((assetAverages) => assetAverages.month)
        .value();

    return assessmentsGroupedByMonth;
};

export const getAverageEmissionByMonthChartData = (
    averageEmissionsByMonth: AverageEmissionByMonth[],
    activeYear: number,
    t: TFunction
) => {
    const chartData = _.chain(averageEmissionsByMonth)
        .map(
            ({
                emissionPerArea,
                month,
                totalArea,
                assetCount,
            }): MonthChartData => {
                const date = DateTime.local(activeYear, month, 1);

                return {
                    value: getConvertedGramsToMassUnit(
                        emissionPerArea,
                        UnitMass.KILOGRAM
                    ),
                    label: date.monthShort,
                    title: t(
                        "portfolioDashboard.hoverBox.avgEmissions",
                        "Building Average"
                    ),
                    header: date.monthLong,
                    formattedValue: getFormattedConvertedGramsToMassUnit(
                        emissionPerArea,
                        UnitMass.KILOGRAM,
                        t,
                        true
                    ),
                    assetCount,
                    totalArea,
                };
            }
        )
        .value();

    return chartData;
};
