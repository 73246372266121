import CheckIcon from "@mui/icons-material/Check";
import {
    Box,
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FilterRangeValue } from "components/AssetsGroup/types";

type Range = {
    key: string;
    value: string;
};

type Props = {
    disabled?: boolean;
    range: Range[];
    onSelectRange: (range: FilterRangeValue) => void;
    onRangeChange?: (range: FilterRangeValue) => void;
};

export function FilterOptionRange(props: Props) {
    const { disabled = false, range, onSelectRange, onRangeChange } = props;

    const [selectedRange, setSelectedRange] = useState<{
        start: string;
        end: string;
    }>({
        start: "",
        end: "",
    });

    const { t } = useTranslation();

    const rangeFromList = range.map((range) => {
        return (
            <MenuItem value={range.key} key={range.key}>
                <Typography fontWeight="bold" display="inline">
                    {range.value}
                </Typography>
            </MenuItem>
        );
    });

    const rangeToList = range.map((range) => {
        return (
            <MenuItem value={range.key} key={range.key}>
                <Typography fontWeight="bold" display="inline">
                    {range.value}
                </Typography>
            </MenuItem>
        );
    });

    function onRangeUpdate(
        key: "start" | "end",
        event: SelectChangeEvent<string>
    ) {
        const newRange = {
            ...selectedRange,
            [key]: event.target.value,
        };

        setSelectedRange(newRange);

        onRangeChange?.(newRange);
    }

    function onSave() {
        onSelectRange({
            start: selectedRange.start,
            end: selectedRange.end,
        });
    }

    return (
        <Box sx={{ display: "flex", flexGrow: 1, gap: 1 }}>
            <FormControl sx={{ flexGrow: 1 }}>
                <InputLabel id="range-from" size="small">
                    {t("common.timeFrame.from", "From")}
                </InputLabel>
                <Select
                    fullWidth
                    size="small"
                    labelId="range-from"
                    value={selectedRange.start}
                    onChange={(event) => onRangeUpdate("start", event)}
                    label={t("common.timeFrame.from", "From")}
                >
                    {rangeFromList}
                </Select>
            </FormControl>

            <Divider sx={{ alignSelf: "center", width: "4px" }} />
            <FormControl sx={{ flexGrow: 1 }}>
                <InputLabel id="range-to" size="small">
                    {t("common.timeFrame.to", "To")}
                </InputLabel>
                <Select
                    fullWidth
                    size="small"
                    labelId="range-to"
                    value={selectedRange.end}
                    onChange={(event) => onRangeUpdate("end", event)}
                    label={t("common.timeFrame.to", "To")}
                >
                    {rangeToList}
                </Select>
            </FormControl>
            <Button
                size="small"
                variant="contained"
                onClick={onSave}
                disabled={disabled}
            >
                <CheckIcon />
            </Button>
        </Box>
    );
}
