import { LineChart, LineChartProps } from "@mui/x-charts";
import { useMemo } from "react";

import { AssetRisk } from "containers/StrategyPage/strategyPage.types";

import { assetRiskListToChartSeries, getXAxisYears } from "./chart.utils";
import { StrategyTooltip } from "./StrategyTooltip";

type Props = {
    assets: AssetRisk[];
    onSelectedAsset: (asset: AssetRisk) => void;
};

export const Chart = (props: Props) => {
    const { assets, onSelectedAsset } = props;

    const [years, series] = useMemo(() => {
        const years = getXAxisYears(assets);
        const series = assetRiskListToChartSeries(assets);

        return [years, series];
    }, [assets]);

    const onMarkClick: LineChartProps["onMarkClick"] = (_, params) => {
        const { seriesId } = params;

        const asset = assets.find((asset) => asset.id === seriesId);

        if (!asset) {
            return;
        }

        onSelectedAsset(asset);
    };

    return (
        <LineChart
            height={500}
            series={series}
            yAxis={[
                {
                    label: "CO2/h",
                    tickNumber: 5,
                    labelStyle: { translate: -10 },
                },
            ]}
            xAxis={[
                {
                    data: years,
                    scaleType: "point",
                    valueFormatter: (date: Date) =>
                        date.getFullYear().toString(),
                },
            ]}
            slots={{
                itemContent: StrategyTooltip,
            }}
            slotProps={{
                itemContent: {
                    years,
                } as any,
                legend: { hidden: true },
            }}
            tooltip={{
                trigger: "item",
            }}
            onMarkClick={onMarkClick}
            axisHighlight={{ x: "none", y: "none" }}
            grid={{ vertical: true, horizontal: true }}
            sx={{
                "& .line-after path": {
                    strokeDasharray: "10 5",
                    stroke: "gray",
                },
                "& .line-after .MuiMarkElement-root": { stroke: "gray" },
                "& .MuiMarkElement-root": { cursor: "pointer" },
                "& .MuiMarkElement-root:hover": { strokeWidth: 4 },
            }}
        />
    );
};
