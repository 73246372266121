import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Button,
} from "@mui/material";
import { useMemo, useState } from "react";

import { StrategyRow } from "./StrategyRow";
import { S } from "../strategyDrawer.style";
import { RowData } from "../strategyDrawer.types";

export type Props = {
    data: RowData[];
    epcs: RowData[];
};

export const StrategyTable = (props: Props) => {
    const { data, epcs } = props;

    const [showEpcs, setShowEpcs] = useState(false);

    const [firstEpc, ...hiddenEpcs] = epcs;

    const tableData = useMemo(
        () => [
            ...data,
            {
                ...firstEpc,
                kpi: showEpcs ? "EPC #1" : "EPCs",
                completed:
                    epcs.length >= 1 && showEpcs
                        ? firstEpc.completed
                        : `${
                              epcs.filter((row) => row.completed === true)
                                  .length
                          }/${epcs.length}`,
            },
        ],
        [firstEpc, epcs, showEpcs, data]
    );

    return (
        <TableContainer>
            <Table padding="none">
                <TableHead>
                    <TableRow>
                        <S.Head>ESG KPI's</S.Head>
                        <S.Head>Current</S.Head>
                        <S.Head>Target</S.Head>
                        <S.Head>Unit</S.Head>
                        <S.Head align="right">Completed</S.Head>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => (
                        <StrategyRow key={`${row.kpi}-${index}`} data={row} />
                    ))}

                    {showEpcs &&
                        hiddenEpcs.map((row, index) => (
                            <StrategyRow
                                key={`epcs-${index}`}
                                data={row}
                                index={index + 2}
                            />
                        ))}
                </TableBody>
            </Table>
            {epcs.length > 1 && (
                <Button
                    variant="text"
                    sx={{
                        opacity: 0.6,
                        padding: 0,
                        textDecorationLine: "underline",
                    }}
                    size="small"
                    onClick={() => setShowEpcs((prevState) => !prevState)}
                >
                    {!showEpcs ? "View more EPCs" : "Hide EPCs"}
                </Button>
            )}
        </TableContainer>
    );
};
