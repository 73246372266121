import { useQuery } from "@apollo/client";
import { ChartsOnAxisClickHandler, ChartsReferenceLine } from "@mui/x-charts";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import {
    getMarketBenchmarkGraphText,
    getBaselineBenchmarkGraphText,
} from "components/PortfolioDashboard/helpers/emptyGraphs";
import { YEARLY_PERFORMANCE_QUERY } from "components/PortfolioDashboard/performanceQuery";
import PortfolioPerformanceSidepanel from "components/PortfolioDashboard/Sidepanel/PortfolioPerformanceSidepanel";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { useOrganizationContext } from "context";
import { Chart } from "glue/Chart";
import {
    Benchmark,
    BenchmarkType,
    AssessmentDataType,
    GetYearlyPerformanceQuery,
    GetYearlyPerformanceQueryVariables,
} from "graphql-types/graphql";

import {
    formatBenchmarkPerformanceLocation,
    getBenchmarkPerformanceChartData,
} from "./benchmarkPerformanceChart.helpers";
import { SideBarState } from "./benchmarkPerformanceChart.types";
import { BenchmarkPerformanceChartTooltip } from "./components/BenchmarkPerformanceChartTooltip";
import { useActiveCountryBenchmark } from "../utils/activeCountryBenchmarkContext";

type Props = {
    benchmarks: Benchmark[];
    benchmarkType: BenchmarkType;
    activeDataType: AssessmentDataType;
};

export const BenchmarkPerformanceChart = (props: Props) => {
    const { activeDataType, benchmarkType, benchmarks } = props;

    const { t } = useTranslation();
    const { activeYear } = useSelectedYearContext();
    const { activeCountryCode } = useActiveCountryBenchmark();

    const { baselineYear } = useOrganizationContext() ?? {};

    const [sideBarState, setSideBarState] = useState<SideBarState | null>(null);
    const hideSidepanel = useCallback(
        () => setSideBarState(null),
        [setSideBarState]
    );

    const activeBenchmark = benchmarks.find(
        (benchmark) =>
            benchmark.type === benchmarkType &&
            (benchmark.countryCode === activeCountryCode ||
                benchmarkType === BenchmarkType.BASELINE)
    );

    const { data, loading } = useQuery<
        GetYearlyPerformanceQuery,
        GetYearlyPerformanceQueryVariables
    >(YEARLY_PERFORMANCE_QUERY, {
        variables: {
            dataType: activeDataType,
            year: activeYear.toString(),
            benchmarkId: activeBenchmark?.id,
        },
        skip: !activeBenchmark,
    });

    const performanceForOrganization = useMemo(() => {
        return data?.getAssets ?? [];
    }, [data]);

    if (!performanceForOrganization || !activeBenchmark) {
        return activeBenchmark?.type === BenchmarkType.MARKET
            ? getMarketBenchmarkGraphText(t)
            : getBaselineBenchmarkGraphText(!!baselineYear, false, t);
    }

    const formattedPerformance = formatBenchmarkPerformanceLocation(
        performanceForOrganization,
        activeDataType
    );

    const { benchmarkValue, sidePanelData, series, xAxis } =
        getBenchmarkPerformanceChartData(
            activeBenchmark,
            activeDataType,
            formattedPerformance,
            t
        );

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <>
            <Chart
                series={series}
                xAxis={xAxis}
                yAxis={[{ tickMinStep: 1 }]}
                sx={{ cursor: "pointer" }}
                customTooltip={BenchmarkPerformanceChartTooltip}
            >
                {benchmarkValue && (
                    <ChartsReferenceLine
                        x="100"
                        lineStyle={{
                            strokeWidth: 2,
                            stroke: "#bababa",
                        }}
                    />
                )}
                <ChartsOnAxisClickHandler
                    onAxisClick={(_, params) =>
                        params &&
                        setSideBarState({
                            locations: sidePanelData[params.dataIndex],
                            performance: (params.axisValue as number) || 0,
                        })
                    }
                />
            </Chart>
            <PortfolioPerformanceSidepanel
                overallPercentage={sideBarState ? sideBarState.performance : 0}
                buildingData={sideBarState ? sideBarState.locations : []}
                location={Boolean(sideBarState)}
                onClose={hideSidepanel}
            />
        </>
    );
};
