import { uniqBy } from "lodash";

import {
    ActiveFilter,
    Asset,
    AssetPreview,
    FilterItemOption,
    FilterItemOptions,
    FilterRangeValue,
    FilterValue,
} from "components/AssetsGroup/types";

function getFilterKeyIdentifier(filterValue: FilterValue | FilterRangeValue) {
    return "key" in filterValue ? filterValue.key : JSON.stringify(filterValue);
}

export function update(
    currentFilters: FilterItemOptions,
    filter: FilterItemOption
): FilterItemOptions {
    const filters = [...currentFilters];

    const hasFilter = filters.some((currentFilter) => {
        return currentFilter.category === filter.category;
    });

    if (!hasFilter) {
        return [...filters, filter];
    }

    const pendingFilters = filters.map((currentFilter) => {
        if (currentFilter.category === filter.category) {
            const values = uniqBy(
                [...currentFilter.values, ...filter.values],
                getFilterKeyIdentifier
            );
            return {
                ...currentFilter,
                values,
            } as FilterItemOption;
        }
        return currentFilter;
    });

    return pendingFilters;
}

export function remove(
    currentFilters: FilterItemOptions,
    activeFilter: ActiveFilter
): FilterItemOptions {
    const filters = [...currentFilters];

    const pendingFilters = filters.map((currentFilter) => {
        if (currentFilter.category === activeFilter.category) {
            const values = (currentFilter.values as FilterValue[]).filter(
                (value) => {
                    return (
                        JSON.stringify(value) !==
                        JSON.stringify(activeFilter.value)
                    );
                }
            );

            return {
                ...currentFilter,
                values,
            } as FilterItemOption;
        }

        return currentFilter;
    });

    const list = pendingFilters.filter((filter) => {
        return filter.values.length > 0;
    });

    return list;
}

export function getAssetsDiff(
    currentAssets: Asset[],
    assets: Asset[]
): AssetPreview[] {
    /**
     * Create Scenario (no saved assets, only new assets)
     * Return the new assets with the status "unchanged"
     * This is to avoid the "new" status for the new assets and have to "paint" the Assets In Group in green color
     */

    if (!currentAssets.length && assets.length) {
        return assets.map((asset) => {
            return {
                ...asset,
                status: "unchanged",
            };
        });
    }

    const assetMap = new Map<string, AssetPreview>();

    // Mark all assets in the new list as "new"
    for (const asset of assets) {
        assetMap.set(asset.id, { ...asset, status: "new" });
    }

    // Update the status for currentAssets in the map
    for (const currentAsset of currentAssets) {
        if (assetMap.has(currentAsset.id)) {
            assetMap.set(currentAsset.id, {
                ...currentAsset,
                status: "unchanged",
            });
        } else {
            assetMap.set(currentAsset.id, {
                ...currentAsset,
                status: "removed",
            });
        }
    }

    return Array.from(assetMap.values());
}

export function toJSON(filters: FilterItemOptions) {
    return filters.map((filter) => {
        const values = filter.values.map(getFilterKeyIdentifier);
        return {
            category: filter.category,
            values,
        };
    });
}

export function toConstructionYearRangeValues(
    filters: FilterValue[]
): FilterRangeValue[] {
    return filters.map((filter) => {
        const [yearStart, yearEnd] = filter.key.split("-");
        return {
            start: yearStart,
            end: yearEnd,
        };
    });
}

export const filtersState = {
    update,
    remove,
    toJSON,
    getAssetsDiff,
    toConstructionYearRangeValues,
};
