import { ChartsItemContentProps } from "@mui/x-charts";
import { getLabel, CommonSeriesType } from "@mui/x-charts/internals";

import { StrategySeriesDefaultized } from "containers/StrategyPage/strategyPage.types";
import { ChartTooltip } from "glue/Chart/components/ChartTooltip";

export type Props = {
    series: StrategySeriesDefaultized;
    years?: Date[];
} & ChartsItemContentProps<"line">;

export const StrategyTooltip = (props: Props) => {
    const { series, itemData, getColor, years } = props;

    if (!itemData.dataIndex || !series.data[itemData.dataIndex]) {
        return null;
    }

    const color = getColor(itemData.dataIndex);

    const value = series.data[itemData.dataIndex];

    const formattedValue = (
        series.valueFormatter as CommonSeriesType<
            typeof value
        >["valueFormatter"]
    )?.(value, { dataIndex: itemData.dataIndex });

    return (
        <ChartTooltip
            sections={[
                {
                    header: years?.[itemData.dataIndex]
                        .getFullYear()
                        .toString(),
                    subHeaders: [
                        {
                            label: "Intensity",
                            value: formattedValue ?? "Error",
                        },
                        {
                            label: "Asset Type",
                            value: getLabel(series.label, "tooltip") ?? "Error",
                            iconColor: color,
                        },
                    ],
                },
                {
                    items: [
                        {
                            label: "Internal Id",
                            value: series.internalId?.toString() || "",
                        },

                        {
                            label: "Construction Year",
                            value: series.constructionYear?.toString() || "",
                        },
                    ],
                },
            ]}
        />
    );
};
