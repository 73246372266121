import { GridToolbarColumnsButton } from "@mui/x-data-grid";

import {
    TableToolbarExport,
    toolbarSlotProps,
} from "components/Table/TableToolbarExport";

import { S } from "./components.styles";

type Props = {
    fileName: string;
};

export const Toolbar = (props: Props) => (
    <S.ToolbarContainer>
        <GridToolbarColumnsButton slotProps={toolbarSlotProps} />
        <TableToolbarExport
            csvOptions={{ fileName: props.fileName }}
            hasCustomStyle={true}
        />
    </S.ToolbarContainer>
);
