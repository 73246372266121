import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useDownloadContext } from "components/PortfolioDashboard/helpers/useDownloadContext";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { BenchmarkMarkers } from "components/types";
import { Chart } from "glue/Chart";

import {
    getYearlyPortfolioIntensityChartCSVData,
    getYearlyPortfolioIntensityChartData,
} from "./yearlyIntensityChart.helpers";
import { AssetEmissionChartTooltip } from "../AssetEmissionChartTooltip";
import { benchmarksToSeries, legacyBenchmarkToSeries } from "../utils";

type Props = {
    assessmentSummaries: SummaryYearOverview[];
    benchmarkMarkers: BenchmarkMarkers;
};

export const YearlyIntensityChart = (props: Props) => {
    const { assessmentSummaries, benchmarkMarkers } = props;
    const { t } = useTranslation();

    const { setDownloadData } = useDownloadContext();

    const { series, xAxis, years } = useMemo(() => {
        return getYearlyPortfolioIntensityChartData(t, assessmentSummaries);
    }, [t, assessmentSummaries]);

    const benchmarkSeries = useMemo(() => {
        const benchmarkSeries = benchmarksToSeries(
            benchmarkMarkers,
            years.length,
            false,
            t
        );
        const legacyBenchmarkSeries = legacyBenchmarkToSeries(
            years,
            benchmarkMarkers,
            false,
            t
        );

        return [...benchmarkSeries, ...legacyBenchmarkSeries];
    }, [benchmarkMarkers, years, t]);

    useEffect(() => {
        const csvData = getYearlyPortfolioIntensityChartCSVData(
            years,
            series,
            benchmarkSeries,
            t
        );

        setDownloadData({
            data: csvData,
            fileName: "yearly-intensity-chart.csv",
        });
    }, [setDownloadData, series, years, benchmarkSeries, t]);

    return (
        <Chart
            series={[...series, ...benchmarkSeries]}
            xAxis={xAxis}
            yAxis={[
                {
                    label: t(
                        "portfolioDashboard.chartHelpers.kgCO2em2",
                        "kg CO2e / m²"
                    ),
                },
            ]}
            customTooltip={AssetEmissionChartTooltip}
        />
    );
};
