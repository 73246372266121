import { SeriesValueFormatter } from "@mui/x-charts/internals";
import { TFunction } from "i18next";
import _ from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { getCurrentYear } from "utils/date.utils";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedValueToMassUnit, getYearLabel } from "../charts.helper";
import { PortfolioChartData } from "../types/chart.types";

export const getTotalEmissionsChartData = (
    t: TFunction,
    assessmentSummaries: SummaryYearOverview[]
): PortfolioChartData => {
    const data = assessmentSummaries.map(
        ({ year, assetCount, totalArea, emission, projectedEmission }) => {
            const isCurrentYear = getCurrentYear() === year;
            const calculatedProjectedEmission = projectedEmission - emission;

            return {
                xAxisData: year,
                assetCount: assetCount,
                totalArea: totalArea,
                seriesData: getConvertedGramsToMassUnit(emission, UnitMass.TON),
                projectedSeriesData:
                    isCurrentYear && calculatedProjectedEmission > 0
                        ? getConvertedGramsToMassUnit(
                              calculatedProjectedEmission,
                              UnitMass.TON
                          )
                        : null,
            };
        }
    );

    const [xAxisData, assetCount, totalArea, seriesData, projectedSeriesData] =
        _.chain(data).map(Object.values).unzip().value();

    const valueFormatter: SeriesValueFormatter<number | null> = (value) =>
        getFormattedValueToMassUnit(value, UnitMass.TON, t, false) || "";

    return {
        series: [
            {
                type: "bar",
                label: t(
                    "portfolioDashboard.boxes.emissions",
                    "Total Emissions"
                ),
                stack: "total",
                id: "emission",
                data: seriesData,
                assetCount,
                totalArea,
                valueFormatter,
            },
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.projected", "Projected"),
                stack: "total",
                id: "projected",
                data: projectedSeriesData,
                valueFormatter,
            },
        ],
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
    };
};
