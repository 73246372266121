import { useQuery } from "@apollo/client";
import { getReplay, setUser as setSentryUser } from "@sentry/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useMemo,
} from "react";

import { GetMeQuery } from "graphql-types/graphql";
import auth from "utils/auth";

import ME_QUERY from "../queries/me";
import { getReportingYear } from "../utils/date.utils";
import { updateFlagsWithContext } from "../utils/featureFlags";

type User = GetMeQuery["me"];

const UserContext = createContext<User | undefined>(undefined);

export const useReportingYearContext = () => {
    const context = useContext(UserContext);

    return getReportingYear(context?.organization?.reportingYear);
};

export const useOrganizationContext = () => {
    const context = useContext(UserContext);

    return context?.organization ?? undefined;
};

export const useUserContext = () => {
    const context = useContext(UserContext);

    return context || undefined;
};

export const UserProvider = (props: PropsWithChildren) => {
    const { data } = useQuery<GetMeQuery>(ME_QUERY);

    const ldClient = useLDClient();

    const user = useMemo(() => {
        const userInfo = auth.getUserInfo();
        if (!data?.me && userInfo) {
            const { organizationName, organizationId, ...user } = userInfo;

            return {
                ...user,
                organization: {
                    id: organizationId,
                    name: organizationName,
                    allLocationsCount: 1,
                },
            };
        }

        return data?.me;
    }, [data]);

    useEffect(() => {
        if (!user || !user.organization) return;

        const { id, name, email, organization } = user;

        setSentryUser({
            id,
            username: name ?? "",
            email,
            organization: organization.name,
        });

        if (!email.includes("@wearelegacy.dk")) {
            const replay = getReplay();

            replay?.flush();
        }

        if (!ldClient) return;

        updateFlagsWithContext(
            ldClient,
            organization.id ?? "",
            organization.name,
            id,
            name ?? ""
        );
    }, [ldClient, user]);

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    );
};
