import { useQuery } from "@apollo/client";
import {
    GridRowId,
    GridPaginationModel,
    GridPaginationMeta,
    GridSortModel,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { ActiveBenchmarkContext } from "components/BenchmarkSelect/BenchmarkSelect";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import {
    AssessmentDataType,
    GetAssetsQuery,
    GetAssetsQueryVariables,
    LocationSortFields,
    SortDirection,
} from "graphql-types/graphql";

import { GET_ASSETS } from "./asset-list.query";
import {
    formatQueryFilter,
    formatQuerySort,
    PerformanceFilter,
    QueryFilter,
} from "./helpers";

const PAGE_SIZE = 20;

type HiddenColumns = "performance" | "dataQuality" | "verticals";

/**
 * Based on the pagination guide on the official documentation:
 * https://mui.com/x/react-data-grid/pagination/#cursor-based-pagination
 */
export const useGetAssetsQuery = (hiddenColumns: HiddenColumns[]) => {
    const { activeYear } = useSelectedYearContext();
    const { activeBenchmark } = useContext(ActiveBenchmarkContext);

    // Filtering state
    const [filters, setFilters] = useState<QueryFilter>({
        dataType: AssessmentDataType.COMBINED,
        inputString: null,
        performance: PerformanceFilter.ALL,
    });

    const handleFilterChange = debounce(
        (key: keyof QueryFilter, value: any) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: value,
            }));
        },
        200
    );

    // Sorting state
    const [queryOptions, setQueryOptions] = useState({
        field: LocationSortFields.EXTERNALID,
        direction: SortDirection.ASC,
    });

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptions(formatQuerySort(sortModel));
    }, []);

    // Pagination model
    const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: PAGE_SIZE,
    });

    // Query
    const skipPerformance =
        hiddenColumns.includes("performance") || activeBenchmark === null;

    const { data, previousData, loading } = useQuery<
        GetAssetsQuery,
        GetAssetsQueryVariables
    >(GET_ASSETS, {
        variables: {
            paging: {
                first: PAGE_SIZE,
                after: mapPageToNextCursor.current[paginationModel.page - 1],
            },
            sorting: queryOptions,
            filter: formatQueryFilter(filters, activeYear, activeBenchmark?.id),
            dataType: filters.dataType,
            fromLower: activeYear.toString(),
            fromUpper: `${activeYear}-12-31:23:59:59`,
            benchmarkId: activeBenchmark?.id,
            skipPerformance,
            skipDataQuality: hiddenColumns.includes("dataQuality"),
            skipVerticals: hiddenColumns.includes("verticals"),
        },
    });

    const { hasNextPage, endCursor } = data?.locations.pageInfo || {};

    // Pagination model change
    const handlePaginationModelChange = (
        newPaginationModel: GridPaginationModel
    ) => {
        // We have the cursor, we can allow the page transition.
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setPaginationModel(newPaginationModel);
        }
    };

    // Row count
    const [rowCount, setRowCount] = useState(0);
    useEffect(() => {
        const currentRowCount = data?.locations.totalCount;

        setRowCount((prevRowCountState) =>
            currentRowCount !== undefined ? currentRowCount : prevRowCountState
        );
    }, [data?.locations.totalCount]);

    // Pagination meta
    const paginationMetaRef = useRef<GridPaginationMeta>();
    const paginationMeta = useMemo(() => {
        if (
            typeof hasNextPage === "boolean" &&
            paginationMetaRef.current?.hasNextPage !== hasNextPage
        ) {
            paginationMetaRef.current = { hasNextPage };
        }
        return paginationMetaRef.current;
    }, [hasNextPage]);

    // Save the cursor for the next page
    useEffect(() => {
        if (!loading && endCursor) {
            mapPageToNextCursor.current[paginationModel.page] = endCursor;
        }
    }, [paginationModel.page, loading, endCursor]);

    const assets =
        data?.locations?.edges.map(({ node }) => node) ??
        previousData?.locations?.edges.map(({ node }) => node) ??
        [];

    return {
        assets,
        loading,
        filterProps: {
            queryFilter: filters,
            onFilterChange: handleFilterChange,
        },
        tablePaginationProps: {
            rowCount,
            pageSizeOptions: [PAGE_SIZE],
            paginationModel,
            paginationMeta,
            onPaginationModelChange: handlePaginationModelChange,
            onSortModelChange: handleSortModelChange,
        },
    };
};
