import PortfolioDashboard from "components/PortfolioDashboard/PortfolioDashboard";
import AssessmentDataTypeContextProvider from "containers/AssetPage/AssessmentDataTypeContext";
import { BenchmarksProvider } from "utils/benchmarksContext";

function PortfolioPage() {
    return (
        <AssessmentDataTypeContextProvider>
            <BenchmarksProvider>
                <PortfolioDashboard />
            </BenchmarksProvider>
        </AssessmentDataTypeContextProvider>
    );
}

export default PortfolioPage;
