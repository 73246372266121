import { Box } from "@mui/material";

import { AssetGroupFilterCategory } from "graphql-types/graphql";
import {
    filtersState,
    FilterItemOption,
    FilterValue,
    FilterItemOptions,
} from "utils";

import { FilterOptionConstructionYear } from "./FilterOptionConstructionYear";
import { FilterOptionCrremPropertyType } from "./FilterOptionCRREM";
import { FilterOptionPropertySize } from "./FilterPropertySize/FilterPropertySize";

type Props = {
    filters: FilterItemOptions;
    onChange: (filter: FilterItemOption) => void;
};

export const Filters = (props: Props) => {
    const { filters, onChange } = props;

    function onConstructionYearChange(filter: FilterItemOption) {
        const values = filtersState.toConstructionYearRangeValues(
            filter.values as FilterValue[]
        );
        onChange({ ...filter, values });
    }

    const crremFilters = filters.find(
        (filter) =>
            filter.category === AssetGroupFilterCategory.CRREMPROPERTYTYPES
    ) as FilterItemOption;

    return (
        <Box sx={{ display: "flex", gap: 4 }}>
            <FilterOptionCrremPropertyType
                selectedFilters={crremFilters}
                onChange={onChange}
            />
            <FilterOptionPropertySize onChange={onChange} />
            <FilterOptionConstructionYear onChange={onConstructionYearChange} />
        </Box>
    );
};
