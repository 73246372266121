import { gql } from "@apollo/client";

export const PORTFOLIO_OVERVIEW_QUERY = gql`
    query GetPortfolioOverviewAssessmentSummaries(
        $from: DateTime
        $assetGroupId: ID
    ) {
        getAssets(assetGroupId: $assetGroupId) {
            id
            ownedArea
            assessmentSummaries {
                id
                from
                to
                emissionDataGrams {
                    emission {
                        epc {
                            ownedEmission
                        }
                        bestEffort {
                            ownedEmission
                        }
                    }
                    emissionPerArea {
                        epc {
                            ownedEmission
                        }
                        bestEffort {
                            ownedEmission
                        }
                    }
                }
            }
            projectedAssessmentSummaries(filter: { from: { eq: $from } }) {
                id
                from
                to
                emissionDataGrams {
                    emission {
                        epc {
                            ownedEmission
                        }
                        bestEffort {
                            ownedEmission
                        }
                    }
                    emissionPerArea {
                        epc {
                            ownedEmission
                        }
                        bestEffort {
                            ownedEmission
                        }
                    }
                }
            }
        }
    }
`;
