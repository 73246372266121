import {
    GridColDef,
    GridCellParams,
    GridRowsProp,
    DataGridProps,
    GridValidRowModel,
    GridCellModesModel,
    GridCellModes,
} from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { S } from "./table.style";
import { getValueFormatter } from "../../utils/report.helpers";

const PAGE_HEADER_HEIGHT = 400;
export const DYNAMIC_GRID_TABLE_HEIGHT = `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`;

type Props = {
    rows: GridRowsProp;
    columns: GridColDef[];
    sortable?: boolean;
    filterable?: boolean;
    setCustomHeight?: string;
    hasBorder?: boolean;
    isColumnHeaderVerticallyCentered?: boolean;
    isRowAlignedTop?: boolean;
    columnHeaderHeight?: number;
    isAutoRowHeight?: boolean;
    initialHiddenColumns?: string[];
    updateHiddenColumns?: (hiddenColumns: string[]) => void;
    isLoading?: boolean;
    onCellEdit?: (
        updatedRow: GridValidRowModel,
        oldRow: GridValidRowModel
    ) => GridValidRowModel;
    minHeight?: string;
};

export const Table = (props: Props & DataGridProps) => {
    const {
        columns,
        sortable,
        filterable,
        setCustomHeight,
        hasBorder,
        isColumnHeaderVerticallyCentered = true,
        isRowAlignedTop,
        columnHeaderHeight,
        isAutoRowHeight,
        initialHiddenColumns,
        updateHiddenColumns,
        isLoading,
        onCellEdit,
        minHeight,
        ...rest
    } = props;

    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>(
        {}
    );

    const { t } = useTranslation();

    const mappedColumns = useMemo(
        () =>
            columns.map((column) => ({
                sortable,
                filterable,
                hide: false,
                hideSortIcons: false,
                disableSelectionOnClick: true,
                valueFormatter: getValueFormatter(t),
                ...column,
            })),
        [t, columns, sortable, filterable]
    );

    const rowHeight = isAutoRowHeight ? "auto" : 58;

    const initialState = useMemo(
        () => ({
            columns: {
                columnVisibilityModel: initialHiddenColumns?.reduce(
                    (columns, column) => ({
                        ...columns,
                        [column]: false,
                    }),
                    {}
                ),
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * Code following MUI recipe to allow single click editing:
     * https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing
     */
    const onColumnVisibilityModelChange = useCallback(
        (params: Record<string, boolean>) => {
            if (updateHiddenColumns) {
                updateHiddenColumns(
                    Object.keys(params).filter((column) => !params[column])
                );
            }
        },
        [updateHiddenColumns]
    );
    const handleCellClick = useCallback((params: GridCellParams) => {
        if (params.isEditable) {
            setCellModesModel((prevModel) => {
                return {
                    // Revert the mode of the other cells from other rows
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: { mode: GridCellModes.View },
                                }),
                                {}
                            ),
                        }),
                        {}
                    ),
                    [params.id]: {
                        // Revert the mode of other cells in the same row
                        ...Object.keys(prevModel[params.id] || {}).reduce(
                            (acc, field) => ({
                                ...acc,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {}
                        ),
                        [params.field]: { mode: GridCellModes.Edit },
                    },
                };
            });
        }
    }, []);
    const handleCellModesModelChange = useCallback(
        (newModel: GridCellModesModel) => {
            setCellModesModel(newModel);
        },
        []
    );

    return (
        <S.Container style={{ height: setCustomHeight, minHeight }}>
            <S.Grid
                {...rest}
                localeText={{ toolbarExport: t("common.download", "Download") }}
                columns={mappedColumns}
                columnHeaderHeight={columnHeaderHeight ?? 68}
                getRowHeight={() => rowHeight}
                hideFooterSelectedRowCount={true}
                disableRowSelectionOnClick={true}
                disableColumnMenu={true}
                hasBorder={!!hasBorder}
                isColumnHeaderVerticallyCentered={
                    isColumnHeaderVerticallyCentered
                }
                isRowAlignedTop={!!isRowAlignedTop}
                initialState={initialState}
                onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                loading={isLoading ?? false}
                processRowUpdate={onCellEdit}
                cellModesModel={cellModesModel}
                onCellClick={handleCellClick}
                onCellModesModelChange={handleCellModesModelChange}
            />
        </S.Container>
    );
};
