import { chain, sumBy } from "lodash";
import { DateTime } from "luxon";

import { OrgEmissionSummaryFragment } from "graphql-types/graphql";

import {
    AssetEmission,
    AssetWithAreaInformation,
} from "../types/monthlyChart.types";

export const getTotalEmissionSummariesEmissionByMonth = (
    assets: AssetWithAreaInformation[],
    orgEmissionSummaries: OrgEmissionSummaryFragment[]
): AssetEmission[] => {
    const totalArea = chain(assets)
        .filter((asset) => Boolean(asset.hasData && asset.ownedArea))
        .sumBy((asset) => asset.ownedArea || 0)
        .value();

    return chain(orgEmissionSummaries)
        .groupBy(
            (emissionSummary) => DateTime.fromISO(emissionSummary.from).month
        )
        .map((emissionSummariesByMonth, month) => {
            const emission = sumBy(
                emissionSummariesByMonth,
                (e) => e.ownedEmission || 0
            );
            const assetArea = sumBy(
                emissionSummariesByMonth,
                (e) => e.ownedArea || 0
            );

            return {
                month: Number(month),
                assetArea,
                totalArea,
                assetCount: assets.length,
                emission,
                emissionPerArea: emission / assetArea,
            };
        })
        .sortBy((emissionSummariesByMonth) => emissionSummariesByMonth.month)
        .value();
};
