import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
    CreateOrganizationIntegrationMutation,
    CreateOrganizationIntegrationMutationVariables,
    GetOrganizationIntegrationsQuery,
    IntegrationType,
    UpdateOrganizationIntegrationMutation,
    UpdateOrganizationIntegrationMutationVariables,
} from "graphql-types/graphql";

import {
    ADD_ORGANIZATION_INTEGRATION,
    GET_ORGANIZATION_INTEGRATIONS,
    UPDATE_ORGANIZATION_INTEGRATION,
} from "./queries";

export const useOrganizationIntegration = () => {
    const { t } = useTranslation();

    const { data, loading } = useQuery<GetOrganizationIntegrationsQuery>(
        GET_ORGANIZATION_INTEGRATIONS
    );

    const [addOrganizationIntegration] = useMutation<
        CreateOrganizationIntegrationMutation,
        CreateOrganizationIntegrationMutationVariables
    >(ADD_ORGANIZATION_INTEGRATION, {
        refetchQueries: [GET_ORGANIZATION_INTEGRATIONS],
    });

    const [updateOrganizationIntegration] = useMutation<
        UpdateOrganizationIntegrationMutation,
        UpdateOrganizationIntegrationMutationVariables
    >(UPDATE_ORGANIZATION_INTEGRATION, {
        refetchQueries: [GET_ORGANIZATION_INTEGRATIONS],
        //clear the cache for the meter locations query
        update(cache, { data }) {
            const id = data?.updateOneOrganizationIntegration.id || null;
            if (!id) return;
            cache.evict({ id: "ROOT_QUERY" });
        },
    });

    const integrations =
        data?.me.organization?.integrations?.map(
            (integration) => integration.type
        ) ?? [];

    const handleAddOrganizationIntegration = (
        integrationType: IntegrationType,
        data?: Record<string, unknown>
    ) => {
        const dataString = data ? JSON.stringify(data) : null;

        toast.promise(
            addOrganizationIntegration({
                variables: {
                    type: integrationType,
                    data: dataString,
                },
            }),
            {
                success: t(
                    "integrationOnboarding.success",
                    `{{integrationType}} integration created!`,
                    { integrationType }
                ),
                error: t("integrationOnboarding.error", "Error!"),
            }
        );
    };

    const handleUpdateOrganizationIntegration = (
        type: IntegrationType,
        updateData?: Record<string, unknown>
    ) => {
        const dataString = updateData ? JSON.stringify(updateData) : null;

        const integrationId = data?.me.organization?.integrations?.find(
            (integration) => integration.type === type
        )?.id;

        if (!integrationId) return;

        const variables = {
            input: {
                id: integrationId,
                update: { data: dataString },
            },
        };

        toast.promise(
            updateOrganizationIntegration({
                variables,
            }),
            {
                success: t("labels.updated", "Updated"),
                error: t("integrationOnboarding.error", "Error!"),
            }
        );
    };

    return {
        add: handleAddOrganizationIntegration,
        update: handleUpdateOrganizationIntegration,
        loading,
        integrations,
    };
};
