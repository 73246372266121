import {
    MenuItem,
    Checkbox,
    ListItemText,
    FormControl,
    SelectChangeEvent,
    Select,
    OutlinedInput,
    InputLabel,
} from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export enum AssetsCheckboxValue {
    EPC = "EPC Assets",
    METER = "Meter Assets",
    MISSING = "Missing Data Assets",
}

const InputStyle = {
    background: "#fff",

    "&.Mui-focused fieldset": {
        background: "transparent",
        color: "currentColor",
    },
};

interface AssetsProps {
    onChange: (value: AssetsCheckboxValue[]) => void;
    value: AssetsCheckboxValue[];
}

function AssetsCheckbox(props: AssetsProps) {
    const { onChange, value } = props;
    const { t } = useTranslation();

    const dateSources = [
        {
            id: AssetsCheckboxValue.EPC,
            value: t(
                "assessmentOverview.assetList.filter.epcAssets",
                "EPC Assets"
            ),
        },
        {
            id: AssetsCheckboxValue.METER,
            value: t(
                "assessmentOverview.assetList.filter.meterAssets",
                "Meter Assets"
            ),
        },
        {
            id: AssetsCheckboxValue.MISSING,
            value: t(
                "assessmentOverview.assetList.filter.missingDataAssets",
                "Missing Data Assets"
            ),
        },
    ];

    const onDataSourceChange = useCallback(
        (e: SelectChangeEvent<AssetsCheckboxValue[]>) => {
            const value =
                // On autofill we get a stringified value.
                typeof e.target.value === "string"
                    ? (e.target.value.split(",") as AssetsCheckboxValue[])
                    : e.target.value;

            onChange(value);
        },
        [onChange]
    );

    return (
        <FormControl
            sx={{ minWidth: "250px", maxWidth: "350px", height: "100%" }}
        >
            <InputLabel id="verticals-label" size="small">
                {value.length > 0
                    ? t(
                          "assessmentOverview.assetList.filter.assetsWith",
                          "Assets with"
                      )
                    : t(
                          "assessmentOverview.assetList.filter.combinedDataSources",
                          "Combined Data Sources"
                      )}
            </InputLabel>
            <Select
                multiple
                size="small"
                labelId="verticals-label"
                input={
                    <OutlinedInput
                        sx={InputStyle}
                        label={
                            value.length > 0
                                ? t(
                                      "assessmentOverview.assetList.filter.assetsWith",
                                      "Assets with"
                                  )
                                : t(
                                      "assessmentOverview.assetList.filter.combinedDataSources",
                                      "Combined Data Sources"
                                  )
                        }
                    />
                }
                value={value}
                renderValue={(selected: any) => {
                    const selectedValuesString = selected.toString();

                    if (selectedValuesString === AssetsCheckboxValue.EPC) {
                        return t(
                            "assessmentOverview.assetList.filter.epc",
                            "EPC"
                        );
                    } else if (
                        selectedValuesString === AssetsCheckboxValue.METER
                    ) {
                        return t(
                            "assessmentOverview.assetList.filter.meter",
                            "Meter"
                        );
                    } else if (
                        selectedValuesString === AssetsCheckboxValue.MISSING
                    ) {
                        return t(
                            "assessmentOverview.assetList.filter.missing",
                            "No Source"
                        );
                    } else if (
                        selectedValuesString ===
                        AssetsCheckboxValue.EPC +
                            "," +
                            AssetsCheckboxValue.METER
                    ) {
                        return t(
                            "assessmentOverview.assetList.filter.combinedDataSources",
                            "Combined Data Sources"
                        );
                    } else if (
                        selectedValuesString ===
                        AssetsCheckboxValue.EPC +
                            "," +
                            AssetsCheckboxValue.MISSING
                    ) {
                        return (
                            <>
                                {t(
                                    "assessmentOverview.assetList.filter.epc",
                                    "EPC"
                                )}
                                ,{" "}
                                {t(
                                    "assessmentOverview.assetList.filter.missing",
                                    "No Source"
                                )}
                            </>
                        );
                    } else if (
                        selectedValuesString ===
                        AssetsCheckboxValue.METER +
                            "," +
                            AssetsCheckboxValue.MISSING
                    ) {
                        return (
                            <>
                                {t(
                                    "assessmentOverview.assetList.filter.meter",
                                    "Meter"
                                )}
                                ,{" "}
                                {t(
                                    "assessmentOverview.assetList.filter.missing",
                                    "No Source"
                                )}
                            </>
                        );
                    } else if (
                        selectedValuesString ===
                        AssetsCheckboxValue.EPC +
                            "," +
                            AssetsCheckboxValue.METER +
                            "," +
                            AssetsCheckboxValue.MISSING
                    ) {
                        return (
                            <>
                                {t(
                                    "assessmentOverview.assetList.filter.combinedDataSources",
                                    "Combined Data Sources"
                                )}
                            </>
                        );
                    }
                }}
                onChange={onDataSourceChange}
            >
                {dateSources.map((assets) => (
                    <MenuItem key={assets.value} value={assets.id}>
                        <Checkbox
                            checked={value.indexOf(assets.id) > -1}
                            size="small"
                        />
                        <ListItemText primary={assets.value} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default AssetsCheckbox;
