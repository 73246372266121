import { BarSeriesType } from "@mui/x-charts";
import { AxisValueFormatterContext } from "@mui/x-charts/internals";
import { TFunction } from "i18next";
import { chain, compact, sumBy } from "lodash";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { xAxisBandConfig, yAxisBandConfig } from "glue/Chart/chart.types";
import { OrgEmissionSummaryFragment } from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedValueToMassUnit } from "../charts.helper";
import { AssetAverageByMonth } from "../utils/buildingAverageByMonthChart.utils";

type BaseEmissionByMonth = {
    month: number;
    assetCount: number;
    totalArea: number;
};

type EmissionByMonthSeriesType = {
    assetCount?: number[];
    totalArea?: number[];
} & BarSeriesType;

type MonthlyChartData = {
    series: EmissionByMonthSeriesType[];
    xAxis: xAxisBandConfig[];
    yAxis: yAxisBandConfig[];
};

export const getMonthlyChartData = <T extends BaseEmissionByMonth>(
    data: T[],
    dataKey: keyof T,
    label: string,
    activeYear: number,
    t: TFunction,
    withArea = true
): MonthlyChartData => {
    const seriesData = data.map((item) =>
        getConvertedGramsToMassUnit(item[dataKey], UnitMass.KILOGRAM)
    );
    const xAxisData = data.map(
        ({ month }) => DateTime.local(activeYear, month, 1).monthLong || ""
    );
    const assetCount = data.map(({ assetCount }) => assetCount);
    const totalArea = data.map(({ totalArea }) => totalArea);

    return {
        series: [
            {
                type: "bar",
                label,
                data: seriesData,
                id: "emission",
                assetCount,
                totalArea,
                valueFormatter: (value) =>
                    getFormattedValueToMassUnit(
                        value,
                        UnitMass.KILOGRAM,
                        t,
                        withArea
                    ) || "",
            },
        ],
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (
                    month: string,
                    context: AxisValueFormatterContext
                ) =>
                    context.location === "tick" ? month.substring(0, 3) : month,
            },
        ],
        yAxis: [
            {
                label: withArea
                    ? t(
                          "portfolioDashboard.chartHelpers.kgCO2em2",
                          "kg CO2e / m²"
                      )
                    : t("portfolioDashboard.chartHelpers.kgCO2e", "kg CO2e"),
            },
        ],
    };
};

export const getOrgEmissionSummariesEmissionByMonth = (
    orgEmissionSummaries: OrgEmissionSummaryFragment[]
): AssetAverageByMonth[] => {
    return chain(orgEmissionSummaries)
        .filter((e) => Boolean(e.ownedArea))
        .groupBy(
            (emissionSummary) => DateTime.fromISO(emissionSummary.from).month
        )
        .map((emissionSummariesByMonth, month) => {
            const ownedArea = compact(
                emissionSummariesByMonth.map((es) => es.ownedArea)
            )[0];

            if (!ownedArea) {
                return null;
            }

            const emission = sumBy(
                emissionSummariesByMonth,
                (emissionSummary) => emissionSummary.ownedEmissionWithArea || 0
            );

            return {
                assetArea: ownedArea,
                month: Number(month),
                emissionPerArea: emission / ownedArea,
                emission,
            };
        })
        .compact()
        .sortBy((emissionSummariesByMonth) => emissionSummariesByMonth.month)
        .value();
};
