import { gql } from "@apollo/client";

export const EMISSION_SUMMARIES_QUERY = gql`
    query GetOrgEmissionSummariesForYearlyBreakdown(
        $aggregation: AssessmentAggregation!
        $from: DateTime!
        $to: DateTime!
        $dataType: AssessmentDataType!
        $assetGroupId: ID
    ) {
        getAssets(from: $from, to: $to, assetGroupId: $assetGroupId) {
            id
            hasData(
                from: $from
                to: $to
                aggregation: $aggregation
                dataType: $dataType
            )
            ownedArea
        }
        orgEmissionSummaries(
            aggregation: $aggregation
            from: $from
            to: $to
            dataType: $dataType
            assetGroupId: $assetGroupId
        ) {
            ...OrgEmissionSummary
        }
    }
    fragment OrgEmissionSummary on EmissionSummaryView {
        from
        ownedEmissionWithArea
        ownedEmission
        ownedArea
    }
`;
