import { Circle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import { AssetGroupFilterCategory, CrremCategory } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";

type Props = {
    filters: FilterItemOption[];
};

export const StrategyLegend = (props: Props) => {
    const { filters } = props;

    const { translations } = useAssetFilterTranslations();

    const crremFilters = filters.find(
        (filter) =>
            filter.category === AssetGroupFilterCategory.CRREMPROPERTYTYPES
    )?.values as FilterValue[];

    if (!crremFilters) return;

    return (
        <Stack direction="row" gap={5} justifyContent="center">
            {crremFilters.map((filter) => (
                <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    key={filter.key}
                >
                    {/* Colors will come from crrem */}
                    <Circle sx={{ fontSize: 10 }} htmlColor="blue" />
                    <Typography>
                        {translations[filter.key as CrremCategory]}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
};
