import { Box, styled } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";

export const S = {
    Container: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "0.25rem",
        "& > *": {
            marginBottom: `${theme.spacing(2)} !important`,
        },
        margin: theme.spacing(4, 0),
    })),
    ToolbarContainer: styled(GridToolbarContainer)(({ theme }) => ({
        position: "absolute",
        top: theme.spacing(32),
        right: theme.spacing(12.5),
        boxShadow: theme.shadows[4],
    })),
};
