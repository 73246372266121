import { SeriesValueFormatter } from "@mui/x-charts/internals";
import { TFunction } from "i18next";
import _ from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import {
    getBuildingAverageEmissionPerArea,
    getProjectedBuildingAverageEmissionPerArea,
} from "components/PortfolioDashboard/helpers/chartHelpers";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { xAxisBandConfig } from "glue/Chart/chart.types";
import { AssessmentDataType } from "graphql-types/graphql";
import { getCurrentYear } from "utils/date.utils";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedValueToMassUnit, getYearLabel } from "../charts.helper";
import { PortfolioChartSeriesType } from "../types/chart.types";

export const getYearlyBuildingAverageChartData = (
    t: TFunction,
    activeDataType: AssessmentDataType,
    assessmentSummaries: SummaryYearOverview[]
): {
    series: PortfolioChartSeriesType[];
    xAxis: xAxisBandConfig[];
    years: number[];
} => {
    const data = assessmentSummaries.map(
        ({ year, assetCount, totalArea, locations }) => {
            const isCurrentYear = getCurrentYear() === year;
            const buildingAvgPerArea = getBuildingAverageEmissionPerArea(
                locations,
                activeDataType
            );

            const projectedBuildingAvgPerArea = isCurrentYear
                ? getProjectedBuildingAverageEmissionPerArea(
                      locations,
                      activeDataType
                  )
                : null;

            const calculatedProjectedEmissions =
                projectedBuildingAvgPerArea &&
                buildingAvgPerArea &&
                projectedBuildingAvgPerArea - buildingAvgPerArea;

            return {
                xAxisData: year,
                assetCount: assetCount,
                totalArea: totalArea,
                seriesData: buildingAvgPerArea
                    ? getConvertedGramsToMassUnit(
                          buildingAvgPerArea,
                          UnitMass.KILOGRAM
                      )
                    : null,
                projectedSeriesData: calculatedProjectedEmissions
                    ? getConvertedGramsToMassUnit(
                          calculatedProjectedEmissions,
                          UnitMass.KILOGRAM
                      )
                    : null,
            };
        }
    );

    const [xAxisData, assetCount, totalArea, seriesData, projectedSeriesData] =
        _.chain(data).map(Object.values).unzip().value();

    const valueFormatter: SeriesValueFormatter<number | null> = (value) =>
        getFormattedValueToMassUnit(value, UnitMass.KILOGRAM, t) || "";

    return {
        series: [
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.average", "Average"),
                stack: "total",
                id: "emission",
                data: seriesData,
                assetCount,
                totalArea,
                valueFormatter,
            },
            {
                type: "bar",
                label: t("portfolioDashboard.hoverBox.projected", "Projected"),
                stack: "total",
                id: "projected",
                data: projectedSeriesData,
                valueFormatter,
            },
        ],
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
        years: xAxisData,
    };
};
