import { Paper, styled, Theme } from "@mui/material";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";

type StyledTableProps = { theme: Theme } & {
    hasBorder: boolean;
    isColumnHeaderVerticallyCentered: boolean;
    isRowAlignedTop: boolean;
    onRowClick?: GridEventListener<"rowClick">;
};

export const S = {
    Container: styled(Paper)(({ theme }) => ({
        height: theme.spacing(200),
        width: "100%",
    })),
    Grid: styled(DataGrid, {
        shouldForwardProp: (prop) =>
            prop !== "hasBorder" &&
            prop !== "isColumnHeaderVerticallyCentered" &&
            prop !== "isRowAlignedTop",
    })(
        ({
            theme,
            hasBorder,
            isColumnHeaderVerticallyCentered,
            isRowAlignedTop,
            onRowClick,
        }: StyledTableProps) => ({
            position: "static",

            paddingLeft: hasBorder ? 0 : "auto",
            paddingRight: hasBorder ? 0 : "auto",

            "& .MuiDataGrid-main": {
                border: hasBorder ? "1px solid #E0E0E0" : "none",
            },

            "& .MuiDataGrid-row": {
                cursor: onRowClick && "pointer",
                paddingTop: isRowAlignedTop && "5px",
                paddingBottom: isRowAlignedTop && "5px",

                "&:hover": {
                    backgroundColor: onRowClick && "#f5f8ff",
                },

                "&:hover .show-on-hover": {
                    visibility: "visible",
                },
            },
            "& .MuiButtonBase-root[title='Sort']": {
                paddingTop: 0,
                paddingBottom: 0,
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
                alignItems: isColumnHeaderVerticallyCentered
                    ? "auto"
                    : "flex-start",
                lineHeight: isColumnHeaderVerticallyCentered ? "auto" : "1.2em",
                paddingTop: isColumnHeaderVerticallyCentered
                    ? "auto"
                    : theme.spacing(2.5),
            },
            "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: isRowAlignedTop ? "flex-start" : "center",
            },
            "& .MuiDataGrid-cell:focus": {
                outline: "none",
            },
            "& .no-building-address": {
                color: theme.palette.primary.main,
            },
            "& .header-row": {
                fontWeight: 700,
                backgroundColor: theme.palette.grey[200],
                minHeight: "auto !important",
                maxHeight: "auto !important",
                lineHeight: "1em !important",
                padding: theme.spacing(2, 0),

                "&:after": {
                    backgroundColor: "transparent",
                },
                "&:hover": {
                    backgroundColor: theme.palette.grey[300],
                },
                "& > *": {
                    minHeight: "auto !important",
                    maxHeight: "auto !important",
                    lineHeight: "1.2em !important",

                    "&:not(:first-of-type)": {
                        fontStyle: "italic",
                        fontWeight: 500,
                    },
                },
            },
        })
    ),
};
