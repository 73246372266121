import { sumBy } from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import { AssetFragment } from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

export const formatAsset = (assets: AssetFragment[]) => {
    return assets.map((asset) => {
        const {
            emissionSummaries,
            emissionVerticalSources,
            benchmarkPerformances,
        } = asset;

        const dataQuality = asset.dataQuality?.[0];

        const emission = emissionSummaries.length
            ? sumBy(emissionSummaries, "ownedEmission")
            : null;

        const ownedArea = emissionSummaries[0]?.ownedArea;
        const emissionPerArea =
            emission && ownedArea ? emission / ownedArea : null;

        return {
            id: asset.id,
            ownedArea: ownedArea,
            externalId: asset.externalId,
            displayName: asset.displayName,
            verticals: emissionVerticalSources,
            dataQuality: dataQuality,
            performance: benchmarkPerformances?.[0]?.performance,
            totalEmission: emission
                ? getConvertedGramsToMassUnit(emission, UnitMass.KILOGRAM)
                : null,
            emissionPerArea: emissionPerArea
                ? getConvertedGramsToMassUnit(
                      emissionPerArea,
                      UnitMass.KILOGRAM
                  )
                : null,
        };
    });
};
