import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { AssessmentsOnDate, UnitMass } from "components/AssessmentStats/types";
import { mapDataQualitySources } from "components/DataQuality/DataQuality.helpers";
import {
    AssessmentDataType,
    GetAllAssessmentsQuery,
    IntegrationType,
} from "graphql-types/graphql";
import {
    createYearRange,
    getCurrentYear,
    getYearLabel,
} from "utils/date.utils";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
    getUnitByKey,
} from "utils/report.helpers";

import { aggregateSumBy, getAssessmentMonths } from "./asset-overview.utils";
import { convertDateFromAssessment } from "../AssetDetails/AssetDetails.helpers";

const getAssessmentData = (
    assessmentsOnDate: AssessmentsOnDate[],
    t: TFunction
) => {
    return assessmentsOnDate.map(({ assessments, ...group }) => {
        const { color, vertical } = group;

        if (!assessments) {
            return {
                color,
                vertical,
                consumptionLabels: [],
            };
        }

        const hasEmissions = assessments.some(
            ({ emissionDataGrams }) =>
                emissionDataGrams?.ownedEmission !== null &&
                emissionDataGrams?.ownedEmission !== undefined
        );

        const emission = hasEmissions
            ? getConvertedGramsToMassUnit(
                  aggregateSumBy(
                      assessments,
                      (item) => item.emissionDataGrams?.ownedEmission
                  ) || 0,
                  UnitMass.KILOGRAM
              )
            : null;

        const assessmentsGroupedByUnit = _.groupBy(assessments, (a) => a.unit);
        const consumptionLabels = _.chain(assessmentsGroupedByUnit)
            .map((assessments, unit) => {
                const consumption = aggregateSumBy(
                    assessments,
                    (item) => item.consumptionData?.ownedConsumption
                );

                if (consumption !== null) {
                    const formattedUnit = getUnitByKey(unit, t);
                    return {
                        consumption: consumption,
                        consumptionFormatted: formatNumberLocale(
                            consumption,
                            t
                        ),
                        unit: formattedUnit,
                    };
                }
                return null;
            })
            .compact()
            .value();

        const emissionLabel =
            emission !== null
                ? `${formatNumberLocale(emission, t)} kg CO2e`
                : null;
        const hasEmissionUnit = assessments.some(
            (assessment) => assessment.unit
        );

        const sources = assessments.flatMap((assessment) => {
            const { integration, origin, parentId } = assessment;

            if (integration?.type) {
                return integration.type.includes("EPC") && parentId
                    ? "EPC_PROXY"
                    : mapDataQualitySources(integration.type, t);
            }

            switch (origin?.assessmentType) {
                case AssessmentDataType.ACTUAL:
                    return IntegrationType.MANUAL;
                case AssessmentDataType.ESTIMATE:
                    return parentId ? "EPC_PROXY" : "EPC_MANUAL";
                default:
                    return "UNCATEGORIZED";
            }
        });

        return {
            id: vertical,
            color,
            vertical,
            emission,
            emissionLabel,
            consumptionLabels,
            hasEmissionUnit,
            sources: _.uniq(sources),
        };
    });
};

const getProjectedEmissionForMonth = (
    month: DateTime,
    projectedAssessments: {
        emissionDataGrams?: null | { ownedEmission?: number | null };
        from: string;
    }[]
) => {
    const projectedAssessmentsForMonth = projectedAssessments.filter(
        (assessment) => {
            const assessmentDate = DateTime.fromISO(assessment.from);
            return assessmentDate.month === month.month;
        }
    );

    const projectedEmissionForMonth = _.sumBy(
        projectedAssessmentsForMonth,
        (assessment) => assessment.emissionDataGrams?.ownedEmission || 0
    );

    return projectedEmissionForMonth;
};

export const getYearMonthlyVerticalAssessments = (
    activeYear: number,
    assessmentGroups: AssessmentsOnDate[],
    projectedAssessments:
        | GetAllAssessmentsQuery["location"]["projectedAssessments"]
        | null,
    t: TFunction,
    locationSaleDate?: Date,
    locationPurchaseDate?: Date
) => {
    const assessmentMonths = getAssessmentMonths(
        activeYear,
        locationPurchaseDate,
        locationSaleDate
    );
    const projected =
        activeYear === getCurrentYear() && projectedAssessments
            ? projectedAssessments
            : [];

    const dateGroupedAssessmentGroups = assessmentGroups.map(
        ({ assessments, ...group }) => ({
            ...group,
            assessments: _.groupBy(assessments, (ea) =>
                convertDateFromAssessment(ea.from)
            ),
        })
    );

    return assessmentMonths.map((dateStr) => {
        const date = DateTime.fromFormat(dateStr, "yyyy-MM", { zone: "local" });

        const assessments = dateGroupedAssessmentGroups.map(
            ({ assessments, ...group }) => ({
                ...group,
                assessments: assessments[dateStr],
            })
        );
        const allAssessments = assessments.flatMap(
            (group) => group.assessments || []
        );
        const assessmentData = getAssessmentData(assessments, t);

        const total = getConvertedGramsToMassUnit(
            aggregateSumBy(
                allAssessments,
                (item) => item.emissionDataGrams?.ownedEmission
            ) || 0,
            UnitMass.KILOGRAM
        );

        const showProjected =
            DateTime.now() < date ||
            DateTime.now().toFormat("yyyy-MM") === dateStr;

        const projectedTotal =
            showProjected || total === null
                ? Math.abs(
                      getConvertedGramsToMassUnit(
                          getProjectedEmissionForMonth(date, projected),
                          UnitMass.KILOGRAM
                      ) - (total || 0)
                  )
                : null;

        return {
            id: dateStr,
            total,
            data:
                total !== null
                    ? _.keyBy(assessmentData, (data) => data.vertical)
                    : undefined,
            chartLabel: date.toLocaleString({ month: "short" }).toUpperCase(),
            tableRowLabel: date.toLocaleString({ month: "long" }),
            projectedTotal,
        };
    });
};

export const getYearOverYearVerticalAssessments = (
    reportingYear: number,
    assessmentGroups: AssessmentsOnDate[],
    projectedAssessments:
        | GetAllAssessmentsQuery["location"]["projectedAssessments"]
        | null,
    t: TFunction
) => {
    const years = createYearRange(reportingYear)
        .map((year) => year.label)
        .reverse();

    const assessmentGroupedByYear = assessmentGroups.map(
        ({ assessments, ...group }) => ({
            ...group,
            assessments: _.groupBy(
                assessments,
                (ea) => DateTime.fromISO(ea.from).year
            ),
        })
    );

    let isPassFirstYearOfData = false;
    return years.flatMap((year) => {
        if (
            !isPassFirstYearOfData &&
            !assessmentGroupedByYear.some((group) => group.assessments[year])
        ) {
            return [];
        }

        isPassFirstYearOfData = true;

        const assessments = assessmentGroupedByYear.map(
            ({ assessments, ...group }) => ({
                ...group,
                assessments: assessments[year],
            })
        );

        const allAssessments = assessments.flatMap(
            (group) => group.assessments || []
        );
        const assessmentData = getAssessmentData(assessments, t);

        const total = getConvertedGramsToMassUnit(
            aggregateSumBy(
                allAssessments,
                (item) => item.emissionDataGrams?.ownedEmission
            ) || 0,
            UnitMass.KILOGRAM
        );

        const currentMonth = DateTime.local().month;
        const allProjectedAssessments = projectedAssessments
            ?.filter((assessment) => {
                const assessmentDate = DateTime.fromISO(assessment.from);
                return (
                    assessmentDate.year === parseInt(year) &&
                    assessmentDate.month < currentMonth
                );
            })
            .flatMap((assessment) => assessment || []);

        const projected =
            year === getCurrentYear().toString() && projectedAssessments
                ? getConvertedGramsToMassUnit(
                      aggregateSumBy(
                          allProjectedAssessments,
                          (item) => item.emissionDataGrams?.ownedEmission
                      ) || 0,
                      UnitMass.KILOGRAM
                  )
                : null;

        return {
            id: year,
            total,
            data:
                total !== null
                    ? _.keyBy(assessmentData, (data) => data.vertical)
                    : undefined,
            chartLabel: year,
            tableRowLabel: getYearLabel(year, t),
            projectedTotal: projected,
        };
    });
};
