import { gql } from "@apollo/client";

export const DataQualityFragment = gql`
    fragment DataQuality on DataQuality {
        from
        to
        highQuality {
            sources
            assessmentDays
            priorityDistribution
        }
        mediumQuality {
            sources
            assessmentDays
            priorityDistribution
        }
        lowQuality {
            sources
            assessmentDays
            priorityDistribution
        }
    }
`;

export const DATA_QUALITY_QUERY = gql`
    query getDataQuality(
        $assessmentDataType: AssessmentDataType!
        $from: DateTime!
        $to: DateTime!
        $aggregation: AssessmentAggregation
        $assetGroupId: ID
    ) {
        me {
            organization {
                dataQuality(
                    from: $from
                    to: $to
                    filter: { dataType: $assessmentDataType }
                    aggregation: $aggregation
                    assetGroupId: $assetGroupId
                ) {
                    ...DataQuality
                }
            }
        }
    }
    ${DataQualityFragment}
`;
