import { gql } from "@apollo/client";

export const GET_ASSET_RISK_PREVIEW = gql`
    query GetAssetRiskPreview(
        $input: [AssetGroupFilterInput!]!
        $filter: AssessmentSummaryFilter!
    ) {
        getAssetGroupPreview(assetGroupFilters: $input) {
            id
            displayName
            externalId
            constructionDate
            zipCode
            city
            buildingArea
            crremPropertyType
            assessmentSummaries(filter: $filter) {
                from
                to
                emissionDataGrams {
                    emissionPerArea {
                        bestEffort {
                            ownedEmission
                        }
                    }
                }
            }
        }
    }
`;
